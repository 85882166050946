import React from 'react'

const DownloadTemplate = () => {
const fileUrl = 'https://docs.google.com/spreadsheets/d/1fGNpxpSlLhWx_YiKPM7Uz9F9nsSWoSkM/export?format=xlsx';

return (
    // <div>
    //   <div className='font-sarabun text-xl underline text-secondary cursor-pointer' onClick={() => window.open(fileUrl, '_blank', 'width=600,height=400')}>Download file template.</div>
    // </div>
    <div>
      <div
        className="font-sarabun text-xl underline text-secondary cursor-pointer"
        onClick={() => {
          const link = document.createElement("a");
          link.href = fileUrl;
          link.download = "";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }}
      >
        ดาวน์โหลด Template สำหรับอัพโหลด
      </div>
    </div>
  )
}

export default DownloadTemplate