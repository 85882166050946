import React from 'react';
import styles from '../style';

const DateFormat = ({ item }) => {
    return (<div>
        {item.status === 'รับพัสดุสำเร็จ' ? <p className={`${styles.popupTextBody}`}>รับพัสดุสำเร็จ: <label className={`font-bold ${item.status === 'รับพัสดุสำเร็จ' && 'text-green-600'}`}>{item.dateSuccess?.split('T')[0] || "-"} {item.dateSuccess?.split('T')[1] || "-"}</label></p> : <p></p>}
        {item.status === 'พัสดุถูกตีกลับ' ? <p className={`${styles.popupTextBody}`}>พัสดุถูกตีกลับ: <label className={`font-bold ${item.status === 'พัสดุถูกตีกลับ' && 'text-red-500'}`}>{item.dateCancel?.split('T')[0] || "-"} {item.dateCancel?.split('T')[1] || "-"}</label></p> : <p></p>}
        {item.status === 'ขนส่งพัสดุถึงปลายทาง' || item.status === 'พัสดุถูกตีกลับ' || item.status === 'รับพัสดุสำเร็จ'  ? <p className={`${styles.popupTextBody}`}>ขนส่งพัสดุถึงปลายทาง: <label className={`font-bold ${item.status === 'ขนส่งพัสดุถึงปลายทาง' && 'text-blue-600'}`}>{item.dateDelivered?.split('T')[0]} {item.dateDelivered?.split('T')[1] || "-"} </label></p> : <p></p>}
        {item.status === 'กำลังขนส่งพัสดุ' || item.status === 'ขนส่งพัสดุถึงปลายทาง' || item.status === 'พัสดุถูกตีกลับ' || item.status === 'รับพัสดุสำเร็จ' ? <p className={`${styles.popupTextBody}`}>กำลังขนส่งพัสดุ: <label className={`font-bold ${item.status === 'กำลังขนส่งพัสดุ' && 'text-orange-500'}`}>{item.dateDelivery?.split('T')[0]} {item.dateDelivery?.split('T')[1] || "-"}</label></p> : <p></p>}
        {item.status === 'พัสดุเข้าสู่ระบบ' || item.status === 'กำลังขนส่งพัสดุ' || item.status === 'ขนส่งพัสดุถึงปลายทาง' || item.status === 'พัสดุถูกตีกลับ' || item.status === 'รับพัสดุสำเร็จ' ? <p className={`${styles.popupTextBody}`}>พัสดุเข้าสู่ระบบ: <label className={`font-bold ${item.status === 'พัสดุเข้าสู่ระบบ' && 'text-yellow-500'}`}>{item.datePreDelivery?.split('T')[0] } {item.datePreDelivery?.split('T')[1] || "-"}</label></p> : <p></p>}
        </div> );
};

export default DateFormat;