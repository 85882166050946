const styles = {
    boxWidth: "xl:max-w-[1280px] w-full",
  
    navHeading: "font-sarabun text-base",
    pageHeading: "font-sarabun font-bold ss:text-[32px] text-[28px] mb-6 ",
  
    flexCenter: "flex justify-center items-center",
    flexStart: "flex justify-center items-start",
  
    paddingX: "sm:px-10 px-6",
    paddingY: "sm:py-16 py-6",
    padding: "sm:px-16 px-6 sm:py-12 py-4",
  
    marginX: "sm:mx-16 mx-6",
    marginY: "sm:my-16 my-6",

    cardContainer: 'mx-auto',
    cardGrid: 'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 mt-4',
    cardStyle: "rounded-xl shadow-lg border border-borderLine bg-white",
    cardPadding: 'p-5 flex flex-col',
    cardTextHead: "text-lg md:text-xl font-bold font-sarabun mt-1" ,
    cardTextBody: "text-slate-500 font-sarabun sm:text-base text-sm mt-1" ,

    popupTextHead: "text-lg md:text-xl font-bold font-sarabun mb-3" ,
    popupTextBody: "text-slate-500 font-sarabun sm:text-base text-sm mb-1" ,

    fiterSpan: 'font-sarabun mr-2',
    fiterHead: 'font-bold font-sarabun',
    fiterCheckbox: 'mr-1 cursor-pointer',

    dateStyle: 'border border-borderLine rounded-md p-1',

    adminPageBox: 'border border-borderLine p-5 px-8 rounded-md',
    adminPageBtn: 'border border-secondary ss:px-4 px-3 p-2 font-sarabun font-bold text-secondary duration-500',
    adminPageBtnFocus: 'border border-secondary ss:px-4 px-3 p-2 font-sarabun font-bold text-white bg-secondary',
    permissionPageBtn: 'border border-borderLine px-4 p-1 font-sarabun',
    defaultBtn: 'border ss:px-4 px-3 p-2 font-sarabun font-bold duration-500',
    defaultBtnFocus: 'border ss:px-4 px-3 p-2 font-sarabun font-bold text-white',
    confirmBtn: 'border ss:px-4 px-3 p-2 font-sarabun font-bold duration-500 rounded-md mt-2 border-green-600 text-green-600 hover:bg-green-100',
    cancelBtn: 'border ss:px-4 px-3 p-2 font-sarabun font-bold duration-500 rounded-md mt-2 ml-3 border-red-500 text-red-500 hover:bg-red-50',
    adminGrids: 'form-control gap-5 grid grid-cols-1 ss:grid-cols-2 md:grid-cols-3 font-sarabun my-6',
    
    canvas: `border border-borderLine mt-5 rounded-md`,

    customconfirmbutton: 'color: red'
  };
  
  export default styles;
