import React, { useState } from 'react';
import { Route, Routes } from "react-router-dom";
import { MsalAuthenticationTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { Banner, Navbar } from "./components";
import { Login, PopupConfirm, ShowParcels, SendParcelIn,
  SendParcelCompany, ShowParcelsIn, ShowParcelsOut,
  AddParcelIn, AddParcelOut, AddWeighParcel, FeeParcelCompany,
  PermissionManage, } from './pages';

import Export from './pages/admin/Export';
import ExportEm from './pages/employee/ExportEm';
import Approve from './pages/employee/Approve';
import SendParcelsOut from './pages/admin/SendParcelsOut';
import EditParcel from './pages/employee/EditParcel';

function App() {

  const [adminRole, setAdminRole] = useState([]);
  const [openMenu, setOpenMenu] = useState(false); //Control open/close navbar

  return (
    <>
      <Routes>
        <Route path='/employee/popup_confirm' element={<PopupConfirm />} />
        <Route
          path="/*"
          element={
            <MsalAuthenticationTemplate>
              <div className={`w-full h-full mb-8 font-sarabun`}>
                <Banner openMenu={openMenu} setOpenMenu={setOpenMenu} adminRole={adminRole} setAdminRole={setAdminRole} />

                <div className={`w-full h-full flex`}>
                  <Navbar openMenu={openMenu} setOpenMenu={setOpenMenu} />

                  <div className="w-full h-full ss:px-12 px-6 ss:mt-7 mt-4">
                    <Routes>
                      <Route path='/employee/show_parcels' element={<ShowParcels />} />
                      <Route path='/employee/send_parcel' element={<SendParcelCompany />} />
                      <Route path='/employee/send_parcel_in' element={<SendParcelIn />} />
                      <Route path='/employee/export' element={<ExportEm />} />
                      <Route path='/admin/show_parcels_out' element={<ShowParcelsOut />} />
                      <Route path='/admin/show_parcels_in' element={<ShowParcelsIn />} />
                      <Route path='/admin/add_parcel_in' element={<AddParcelIn />} />
                      <Route path='/admin/add_parcel_out' element={<AddParcelOut />} />
                      <Route path='/admin/add_parcel_weigh' element={<AddWeighParcel />} />
                      <Route path='/admin/fee_parcel_company' element={<FeeParcelCompany />} />
                      <Route path='/admin/permission_manage' element={<PermissionManage />} />
                      <Route path='/admin/export' element={<Export />} />
                      <Route path='/' element={<ShowParcels />} />
                      <Route path='/employee/approve' element={<Approve />} />
                      <Route path='/admin/send_parcels_out' element={<SendParcelsOut />} />
                      <Route path='/employee/Edit' element={<EditParcel />} />
                    </Routes>
                  </div>
                </div>
              </div>
            </MsalAuthenticationTemplate>
          }
        />
      </Routes>

      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>

    </>
  );
}

export default App;