import { useState, useEffect } from "react";
import axios from 'axios'
import styles from '../../style';
import { DropdownSearch, Loading } from '../../components'
import { getToken } from "../../services";
import { useMsal } from "../../msalWrapper";
//import { json } from "react-router-dom";
import Swal from "sweetalert2";

const SendParcelIn = () => {

    const [input, setInput] = useState({
        receiver: '',
        sender: '',
    });

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const eid = e.target.eid;
        setInput((values) => ({ ...values, [name]: value, [name+"Eid"]: eid }));
    };

    function handleClick(name, value, eid) {
        setInput((values) => ({ ...values, [name]: value, [name+"Eid"]: eid }));
    };

    const { instance } = useMsal();
    const [userInfo, setUserInfo] = useState([])
    const [loading, setLoading] = useState(true)

    const getEmpInformation = async (e) => {
        e.preventDefault();
        fetch(`${process.env.REACT_APP_API_BASE_URL}/employees/get-emp-in`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(input),

        }).then(response => response.json())
            .then(data => {
                if (data.length < 2) {
                    // alert('รหัสพนักงานหรือชื่อพนักงานไม่มีอยู่ในระบบ!')
                    Swal.fire({
                        icon: 'warning',
                        title: 'รหัสพนักงานหรือชื่อพนักงานไม่มีอยู่ในระบบ!',
                        confirmButtonText: 'ตกลง',
                        confirmButtonColor: '#3085d6',
                      }); 
                } else if (input?.parcelType == null) {
                    // alert('กรุณาเลือกประเภทพัสดุ')
                    Swal.fire({
                        icon: 'warning',
                        title: 'กรุณาเลือกประเภทพัสดุ',
                        confirmButtonText: 'ตกลง',
                        confirmButtonColor: '#3085d6',
                      });
                } else {
                    setUserInfo(data)
                    handlePopupToggle()
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    const [isPopupVisible, setPopupVisibility] = useState(false);

    const handlePopupToggle = () => {
        setPopupVisibility(!isPopupVisible);
    };

    const handleCreateParcel = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_API_BASE_URL}/parcels/create-parcel-user`,
                { userInfo });
            // alert("บันทึกข้อมูลสำเร็จ!");
            // window.location.reload();
            Swal.fire({
                icon: 'success',
                title: 'บันทึกข้อมูลสำเร็จ!',
                text: 'กรุณานำพัสดุไปส่งที่ห้องพัสดุ',
                confirmButtonText: 'ตกลง',
                confirmButtonColor: '#3085d6',
              }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload();
                }
              }); 
            console.log('Data inserted successfully');
        } catch (error) {
            // alert("บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง");
            Swal.fire({
                icon: 'error',
                title: 'บันทึกข้อมูลไม่สำเร็จ',
                text: 'กรุณาลองใหม่อีกครั้ง',
                confirmButtonText: 'ตกลง',
                confirmButtonColor: '#3085d6',
              }); 
            console.error('Error inserting data:', error);
        }
    };

    useEffect(() => {
        const token = getToken();
        const headers = {
            "Content-Type": "application/json",
            id_token: token.idToken,
            access_token: token.accessToken,
        };

        const getUserDefault = async () => {
            try {
                const empData = await axios.get(
                    `${process.env.REACT_APP_API_BASE_URL}/employees/get_emp_one`,
                    { headers: headers }
                );
                if (empData.statusText !== 'OK') {
                    instance.logoutRedirect()
                }
                const empJSON = empData.data

                let name = 'sender';
                let value = `${empJSON[0].nameTH} ${empJSON[0].lastnameTH}`;
                let eid = `${empJSON[0].eid}`;
                setInput((values) => ({ ...values, [name]: value, [name+"Eid"]: eid }));
            } catch (error) {
                console.error("error can't get default employee data.", error);
            } finally {
                setLoading(false)
            }
        }
        getUserDefault();
    }, [instance]);

    return (
        <div>
            <p className={`${styles.pageHeading}`}>บันทึกข้อมูลพัสดุที่จัดส่งภายในหน่วยงาน</p>
            <div className={`${styles.adminPageBox} bg-white mt-12`}>
                {loading ? (
                    <Loading />
                ) : (
                    <form onSubmit={getEmpInformation}>

                        <div className={`${styles.cardContainer}`}>
                            <div className={`${styles.adminGrids} ${styles.adminPageBox}`}>

                                <div>
                                    <label className="label">
                                        <span className="label-text">ชื่อ<span className='font-bold'>ผู้ส่ง</span>พัสดุ หรือรหัสพนักงาน</span>
                                    </label>
                                    <input type="text" name="sender" value={input.sender || ''} placeholder="กรอกข้อมูล" className="input input-bordered w-full " required onChange={handleChange} />
                                    <DropdownSearch searchTerm={input.sender} handleClick={handleClick} name={'sender'} />
                                </div>

                                <div>
                                    <label className="label">
                                        <span className="label-text">ชื่อ<span className='font-bold'>ผู้รับ</span>พัสดุ หรือรหัสพนักงาน</span>
                                    </label>
                                    <input type="text" name="receiver" value={input.receiver || ''} placeholder="กรอกข้อมูล" className="input input-bordered w-full " required onChange={handleChange} />
                                    <DropdownSearch searchTerm={input.receiver} handleClick={handleClick} name={'receiver'} />
                                </div>

                                <div>
                                    <label className="label">
                                        <span className="label-text">ประเภทพัสดุ</span>
                                    </label>
                                    <select name="parcelType" value={input.parcelType || ''} className="input input-bordered w-full" onChange={handleChange}>
                                        <option value="" disabled>กรุณาเลือก</option>
                                        <option value="T02">ซอง</option>
                                        <option value="T01">กล่อง</option>
                                    </select>
                                </div>

                            </div>
                        </div>

                        <input type="submit" value={"บันทึกข้อมูล"} className={`${styles.adminPageBtn} cursor-pointer rounded-md hover:bg-hoverBackground w-full`} />

                    </form>
                )}

                {isPopupVisible && (
                    <div id='container' className='fixed inset-0 z-20 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center'>
                        <div className='bg-white py-5 sm:px-12 px-5 rounded-xl'>
                            <div className="font-bold font-sarabun text-2xl mb-6 flex justify-center">ยืนยันข้อมูล</div>

                            <div className="sm:flex">
                                <div className='mb-3 flex-start border-2 rounded-md sm:p-5 p-2 '>
                                    <div className={`${styles.popupTextHead}`}>ข้อมูลผู้ส่งพัสดุ</div>
                                    <p className={`${styles.popupTextBody}`}>รหัสพนักงาน: <label className="font-bold">{userInfo[0]?.eid}</label></p>
                                    <p className={`${styles.popupTextBody}`}>ชื่อ-นามสกุล: <label className="font-bold">{userInfo[0]?.name} {userInfo[0]?.lastname}</label></p>
                                    <p className={`${styles.popupTextBody}`}>อีเมล: <label className="font-bold">{userInfo[0]?.email}</label></p>
                                    <p className={`${styles.popupTextBody}`}>สังกัดหน่วยงาน: <label className="font-bold">{userInfo[0]?.section || "-"}</label></p>
                                    <p className={`${styles.popupTextBody}`}>ส่วน: <label className="font-bold">{userInfo[0]?.department || "-"}</label></p>
                                    <p className={`${styles.popupTextBody}`}>ฝ่าย: <label className="font-bold">{userInfo[0]?.division || "-"}</label></p>
                                    <p className={`${styles.popupTextBody}`}>ประเภทพัสดุ: <label className="font-bold">{userInfo[0]?.type}</label></p>
                                    <p className={`${styles.popupTextBody}`}>โรงงานต้นทาง: <label className="font-bold">{userInfo[0]?.workArea}</label></p>
                                </div>

                                <div className='mb-3 flex-end border-2 rounded-md sm:p-5 sm:ml-4 p-2 '>
                                    <div className={`${styles.popupTextHead}`}>ข้อมูลผู้รับพัสดุ</div>
                                    <p className={`${styles.popupTextBody}`}>รหัสพนักงาน: <label className="font-bold">{userInfo[1]?.eid}</label></p>
                                    <p className={`${styles.popupTextBody}`}>ชื่อ-นามสกุล: <label className="font-bold">{userInfo[1]?.name} {userInfo[1]?.lastname}</label></p>
                                    <p className={`${styles.popupTextBody}`}>อีเมล: <label className="font-bold">{userInfo[1]?.email}</label></p>
                                    <p className={`${styles.popupTextBody}`}>สังกัดหน่วยงาน: <label className="font-bold">{userInfo[1]?.section || "-"}</label></p>
                                    <p className={`${styles.popupTextBody}`}>ส่วน: <label className="font-bold">{userInfo[1]?.department || "-"}</label></p>
                                    <p className={`${styles.popupTextBody}`}>ฝ่าย: <label className="font-bold">{userInfo[1]?.division || "-"}</label></p>
                                    <p className={`${styles.popupTextBody}`}>โรงงานปลายทาง: <label className="font-bold">{userInfo[1]?.workArea}</label></p>
                                </div>
                            </div>

                            <div className='flex justify-end'>
                                <button className={`${styles.confirmBtn}`} onClick={() => { setPopupVisibility(false); handleCreateParcel(); }}>ยืนยัน</button>
                                <button className={`${styles.cancelBtn} `} onClick={() => setPopupVisibility(false)}>ยกเลิก</button>
                            </div>

                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default SendParcelIn