import { MdEditNote } from "react-icons/md";
import styles from "../style";
import { useState } from "react";

function removeA(arr) {
  var what,
    a = arguments,
    L = a.length,
    ax;
  while (L > 1 && arr.length) {
    what = a[--L];
    while ((ax = arr.indexOf(what)) !== -1) {
      arr.splice(ax, 1);
    }
  }
  return arr;
}

const SearchBar = ({
  setFilterSearch,
  filterCheckbox,
  setFilterCheckbox,
  setStartDate,
  setEndDate,
  showStatusFilters = true,
}) => {
  const [filters, setFilters] = useState(filterCheckbox);

  const updateFilterCheckbox = (filter) => {
    const newFilter = [...filters];
    if (Array.isArray(filter)) {
      filter.forEach((singleFilter) => {
        if (newFilter.some((item) => item === singleFilter)) {
          removeA(newFilter, singleFilter);
        } else {
          newFilter.push(singleFilter);
        }
      });
    } else {
      if (newFilter.some((item) => item === filter)) {
        removeA(newFilter, filter);
      } else {
        newFilter.push(filter);
      }
    }
    setFilters(newFilter);
  };

  const isAdmin = window.location.pathname.startsWith("/admin");
  // const adminRole = JSON.parse(localStorage.getItem("adminRole"));

  return (
    <div className="flex sm:justify-end w-full">
      <input
        type="text"
        placeholder="ค้นหา..."
        onChange={(e) => setFilterSearch(e.target.value)}
        className=" rounded-l-md pl-4 p-2 border border-borderLine font-sarabun focus:outline-none focus:border-secondary"
      />
      <MdEditNote
        size={42}
        className="text-secondary rounded-r-md p-1 border cursor-pointer border-borderLine bg-btnBackground font-sarabun"
        onClick={() => window.my_modal_5.showModal()}
      />
      <dialog id="my_modal_5" className="modal modal-bottom sm:modal-middle">
        <form method="dialog" className="modal-box">
          <h3
            className="font-bold font-sarabun text-2xl mb-6"
            style={{
              display: "flex",
              justifyContent: "center",
              textDecoration: "underline",
            }}
          >
            ตัวกรองค้นหาพัสดุ
          </h3>
          <div className="form-control">
            <div className="mb-3 ">
              <h1 className={`${styles.fiterHead}`}>วันที่</h1>
              <div className="flex items-center">
                <input
                  type="date"
                  name="todate"
                  className={`${styles.dateStyle} `}
                  onChange={(e) => setStartDate(e.target.value)}
                />
                <p className="mx-5 font-bold font-sarabun">ถึง</p>
                <input
                  type="date"
                  name="fromdate"
                  className={`${styles.dateStyle} `}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
            </div>
            {/* <span
              style={{
                display: "flex",
                alignItems: "center",
                border: "1px solid",
                borderColor: "borderLine",
                borderRadius: "0.75rem",
                padding: "0rem",
              }}
            ></span>
            <div className="mb-3">
            <h1 className={`${styles.fiterHead}`}>ประเภทการส่งพัสดุ</h1>
            <input
                type="checkbox"
                defaultChecked
                onClick={() => updateFilterCheckbox("ในนามบริษัท")}
                className={`${styles.fiterCheckbox}`}
              />
              <span className={`${styles.fiterSpan}`}>ในนามบริษัท</span>
              <input
                type="checkbox"
                defaultChecked
                onClick={() => updateFilterCheckbox("ภายในหน่วยงาน")}
                className={`${styles.fiterCheckbox} `}
              />
              <span className={`${styles.fiterSpan}`}>ภายในหน่วยงาน</span>
            </div> */}
            <span
              style={{
                display: "flex",
                alignItems: "center",
                border: "1px solid",
                borderColor: "borderLine",
                borderRadius: "0.75rem",
                padding: "0rem",
                marginBottom: "0.5rem",
                marginTop: "0.5rem",
              }}
            ></span>
            {showStatusFilters === "ShowParcelsOut" ? (
              <div className="mb-3">
                <h1 className={`${styles.fiterHead}`}>สถานะ</h1>
                <input
                  type="checkbox"
                  defaultChecked
                  onClick={() => updateFilterCheckbox("ขนส่งพัสดุถึงปลายทาง")}
                  className={`${styles.fiterCheckbox}`}
                />
                <span className={`${styles.fiterSpan}`}>
                  ขนส่งพัสดุถึงปลายทาง
                </span>
                <br />
                <input
                  type="checkbox"
                  defaultChecked
                  onClick={() => updateFilterCheckbox("รับพัสดุสำเร็จ")}
                  className={`${styles.fiterCheckbox}`}
                />
                <span className={`${styles.fiterSpan}`}>รับพัสดุสำเร็จ</span>
                <br />
                <input
                  type="checkbox"
                  defaultChecked
                  onClick={() => updateFilterCheckbox("พัสดุถูกตีกลับ")}
                  className={`${styles.fiterCheckbox}`}
                />
                <span className={`${styles.fiterSpan}`}>พัสดุถูกตีกลับ</span>
              </div>
            ) : showStatusFilters === "SendParcelsOut" ? (
              <div className="mb-3">
                <h1 className={`${styles.fiterHead}`}>สถานะ</h1>
                <input
                  type="checkbox"
                  defaultChecked
                  onClick={() => updateFilterCheckbox("รออนุมัติ")}
                  className={`${styles.fiterCheckbox}`}
                />
                <span className={`${styles.fiterSpan}`}>รออนุมัติ</span>
                <input
                  type="checkbox"
                  defaultChecked
                  onClick={() => updateFilterCheckbox("ไม่อนุมัติ")}
                  className={`${styles.fiterCheckbox}`}
                />
                <span className={`${styles.fiterSpan}`}>ไม่อนุมัติ</span>
                <input
                  type="checkbox"
                  defaultChecked
                  onClick={() => updateFilterCheckbox("ยังไม่ได้บันทึกค่าส่ง")}
                  className={`${styles.fiterCheckbox}`}
                />
                <span className={`${styles.fiterSpan}`}>
                  ยังไม่ได้บันทึกค่าส่ง
                </span>
                <input
                  type="checkbox"
                  defaultChecked
                  onClick={() => updateFilterCheckbox("บันทึกค่าส่งแล้ว")}
                  className={`${styles.fiterCheckbox}`}
                />
                <span className={`${styles.fiterSpan}`}>บันทึกค่าส่งแล้ว</span>
              </div>
            ) : (
              <>
                <div className="mb-3">
                  <h1 className={`${styles.fiterHead}`}>สถานะ</h1>
                  <input
                    type="checkbox"
                    defaultChecked
                    onClick={() => updateFilterCheckbox("พัสดุเข้าสู่ระบบ")}
                    className={`${styles.fiterCheckbox}`}
                  />
                  <span className={`${styles.fiterSpan}`}>
                    พัสดุเข้าสู่ระบบ
                  </span>
                  <input
                    type="checkbox"
                    defaultChecked
                    onClick={() => updateFilterCheckbox("กำลังขนส่งพัสดุ")}
                    className={`${styles.fiterCheckbox}`}
                  />
                  <span className={`${styles.fiterSpan}`}>กำลังขนส่งพัสดุ</span>
                  <input
                    type="checkbox"
                    defaultChecked
                    onClick={() => updateFilterCheckbox("ขนส่งพัสดุถึงปลายทาง")}
                    className={`${styles.fiterCheckbox}`}
                  />
                  <span className={`${styles.fiterSpan}`}>
                    ขนส่งพัสดุถึงปลายทาง
                  </span>
                  <br />
                  <input
                    type="checkbox"
                    defaultChecked
                    onClick={() => updateFilterCheckbox("รับพัสดุสำเร็จ")}
                    className={`${styles.fiterCheckbox}`}
                  />
                  <span className={`${styles.fiterSpan}`}>รับพัสดุสำเร็จ</span>
                  <input
                    type="checkbox"
                    defaultChecked
                    onClick={() => updateFilterCheckbox("พัสดุถูกตีกลับ")}
                    className={`${styles.fiterCheckbox}`}
                  />
                  <span className={`${styles.fiterSpan}`}>พัสดุถูกตีกลับ</span>
                  <input
                    type="checkbox"
                    defaultChecked
                    onClick={() => updateFilterCheckbox("รออนุมัติ")}
                    className={`${styles.fiterCheckbox}`}
                  />
                  <span className={`${styles.fiterSpan}`}>รออนุมัติ</span>
                  <input
                    type="checkbox"
                    defaultChecked
                    onClick={() => updateFilterCheckbox("ไม่อนุมัติ")}
                    className={`${styles.fiterCheckbox}`}
                  />
                  <span className={`${styles.fiterSpan}`}>ไม่อนุมัติ</span>
                  <input
                    type="checkbox"
                    defaultChecked
                    onClick={() =>
                      updateFilterCheckbox("ยังไม่ได้บันทึกค่าส่ง")
                    }
                    className={`${styles.fiterCheckbox}`}
                  />
                  <span className={`${styles.fiterSpan}`}>
                    ยังไม่ได้บันทึกค่าส่ง
                  </span>
                  <input
                    type="checkbox"
                    defaultChecked
                    onClick={() => updateFilterCheckbox("บันทึกค่าส่งแล้ว")}
                    className={`${styles.fiterCheckbox}`}
                  />
                  <span className={`${styles.fiterSpan}`}>
                    บันทึกค่าส่งแล้ว
                  </span>
                </div>
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid",
                    borderColor: "borderLine",
                    borderRadius: "0.75rem",
                    padding: "0rem",
                    marginBottom: "0.5rem",
                    marginTop: "0.5rem",
                  }}
                ></span>
                <div className="mb-3">
                  <h1 className={`${styles.fiterHead}`}>โรงงาน</h1>
                  <input
                    type="checkbox"
                    defaultChecked={
                      // isAdmin
                      //   ? adminRole[0].workArea === "สำนักงานใหญ่-นวนคร"
                      //   : true
                      true
                    }
                    onClick={() => updateFilterCheckbox("สำนักงานใหญ่-นวนคร")}
                    className={`${styles.fiterCheckbox}`}
                  />
                  <span className={`${styles.fiterSpan}`}>นวนคร</span>
                  <input
                    type="checkbox"
                    defaultChecked={
                      // isAdmin
                      //   ? adminRole[0].workArea === "โรงงานอมตะซิตี้"
                      //   : true
                      true
                    }
                    onClick={() =>
                      updateFilterCheckbox(["อมตะซิตี้", "โรงงานอมตะซิตี้"])
                    }
                    className={`${styles.fiterCheckbox}`}
                  />
                  <span className={`${styles.fiterSpan}`}>อมตะซิตี้</span>
                  <input
                    type="checkbox"
                    defaultChecked={
                      // isAdmin
                      //   ? adminRole[0].workArea === "สำนักงานใหญ่-SKL"
                      //   : true
                      true
                    }
                    onClick={() => updateFilterCheckbox("สำนักงานใหญ่-SKL")}
                    className={`${styles.fiterCheckbox}`}
                  />
                  <span className={`${styles.fiterSpan}`}>ลีสซิ่ง</span>
                  {!isAdmin ? (
                    <>
                      <input
                        type="checkbox"
                        defaultChecked={!isAdmin}
                        onClick={() => updateFilterCheckbox("อื่นๆ")}
                        className={`${styles.fiterCheckbox}`}
                      />
                      <span className={`${styles.fiterSpan}`}>อื่นๆ</span>
                    </>
                  ) : (
                    <div></div>
                  )}
                </div>
              </>
            )}
            <span
              style={{
                display: "flex",
                alignItems: "center",
                border: "1px solid",
                borderColor: "borderLine",
                borderRadius: "0.75rem",
                padding: "0rem",
                marginBottom: "0.5rem",
                marginTop: "0.5rem",
              }}
            ></span>

            <div className="mb-3">
              <h1 className={`${styles.fiterHead}`}>ประเภทพัสดุ</h1>
              <input
                type="checkbox"
                defaultChecked
                onClick={() => updateFilterCheckbox("กล่อง")}
                className={`${styles.fiterCheckbox}`}
              />
              <span className={`${styles.fiterSpan}`}>กล่อง</span>
              <input
                type="checkbox"
                defaultChecked
                onClick={() => updateFilterCheckbox("ซอง")}
                className={`${styles.fiterCheckbox}`}
              />
              <span className={`${styles.fiterSpan}`}>ซอง</span>
            </div>
          </div>
          <div className="modal-action">
            {/* if there is a button in form, it will close the modal */}
            <button
              onClick={() => setFilterCheckbox(filters)}
              className="bg-btnBackground py-3 p-4 rounded-[10px] font-sarabun hover:bg-hoverBackground hover:text-secondary duration-500"
            >
              บันทึก
            </button>
          </div>
        </form>
      </dialog>
    </div>
  );
};

export default SearchBar;
