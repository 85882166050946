import { TbTruckDelivery } from "react-icons/tb"
import { BsFillBoxFill, BsPeopleFill } from "react-icons/bs"
import { FaClipboardList } from "react-icons/fa"
import { MdAssignmentAdd } from "react-icons/md"
import { GiWeight } from "react-icons/gi"
import { HiReceiptTax } from "react-icons/hi";
import { FaFileExport } from "react-icons/fa6";
import { TbCubeSend } from "react-icons/tb";

export const NAVEMP = [
    // {
    //     title: "รายการรออนุมัติ",
    //     path: "/employee/approve",
    //     icon: LuCheckCircle,

    // },
    {
        title: "รายการพัสดุ",
        path: "/employee/show_parcels",
        icon: BsFillBoxFill,

    },
    {
        title: "ส่งพัสดุในนามบริษัท",
        path: "/employee/send_parcel",
        icon: TbTruckDelivery,
    },
    {
        title: "ส่งพัสดุภายในหน่วยงาน",
        path: "/employee/send_parcel_in",
        icon: MdAssignmentAdd,
    },
    {
        title: "เอกสารรายงานพัสดุในนามบริษัท",
        path: "/employee/export",
        icon: FaFileExport,
    }
];

export const NAVADMIN = [
    {
        title: "รายการพัสดุภายใน",
        path: "/admin/show_parcels_in",
        icon: FaClipboardList,
        color: 'text-secondary',
    },
    {
        title: "บันทึกข้อมูลพัสดุภายใน",
        path: "/admin/add_parcel_in",
        icon: MdAssignmentAdd,
        margin: true,
        color: 'text-secondary',
    },
    {
        title: "รายการพัสดุรับจากภายนอก",
        path: "/admin/show_parcels_out",
        icon: FaClipboardList,
        color: 'text-orangeSKC',
    },
    {
        title: "บันทึกข้อมูลพัสดุรับจากภายนอก",
        path: "/admin/add_parcel_out",
        icon: MdAssignmentAdd,
        margin: true,
        color: 'text-orangeSKC',
    },
    {
        title: "บันทึกน้ำหนักพัสดุ",
        path: "/admin/add_parcel_weigh",
        icon: GiWeight,
    },
    {
        title: "อัตราการคิดค่าบริการ",
        path: "/admin/fee_parcel_company",
        icon: HiReceiptTax,
        margin: true,
    },
    {
        title: "รายการพัสดุในนามบริษัท",
        path: "/admin/send_parcels_out",
        icon: TbCubeSend,
    },
    {
        title: "เอกสารรายงานพัสดุในนามบริษัท",
        path: "/admin/export",
        icon: FaFileExport,
    },
    {
        title: "สิทธิ์การเข้าถึง",
        path: "/admin/permission_manage",
        icon: BsPeopleFill,
        margin: true,
    },
];

export const NAVTHAIPOST = [
    {
        title: "บันทึกน้ำหนักพัสดุ",
        path: "/admin/add_parcel_weigh",
        icon: GiWeight,
    },
    {
        title: "รายการพัสดุในนามบริษัท",
        path: "/admin/send_parcels_out",
        icon: TbCubeSend,
    },
    {
        title: "อัตราการคิดค่าบริการ",
        path: "/admin/fee_parcel_company",
        icon: HiReceiptTax,
        margin: true,
    }
];