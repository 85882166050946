import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import styles from '../style';
import { Canvas } from '../components';
import Swal from "sweetalert2";

const PopupConfirm = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const data = urlParams.get('data')?.split(',');

  const [dataDict, setInput] = useState({
    receiverType: '',
    receiverName: '',
  });
  const canvasRef = useRef(null);

  const currentUrl = window.location.href;
  const currentUrlObj = new URL(currentUrl);
  const currentParams = new URLSearchParams(currentUrlObj.search);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInput((values) => ({ ...values, [name]: value }));
    if (name === "receiverType" && value === "รับเอง") {
      setInput(prevState => ({
        ...prevState,
        receiverName: currentParams.get('name')
      }));
    } else if (name === "receiverType" && value === "รับแทน") {
      setInput((values) => ({ ...values, receiverName: '' }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const canvas = canvasRef.current;

    if (canvas) {
      canvas.toBlob(async (blob) => {
        if (blob) {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = async () => {
            const base64data = reader.result;
      
            try {
              const requestData = {
                dataDict,
                tracknumberList: data,
                signatureImage: base64data,
              };
              const requestDataUpdateStatus = {
                status: 'ST03',
                tracknumberList: data,
              };

              await axios.patch(
                `${process.env.REACT_APP_API_BASE_URL}/parcels/update-parcel-status`,
                requestDataUpdateStatus
              );
              await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/parcels/update-parcel-success`, requestData);

              Swal.fire({
                icon: 'success',
                title: 'ลงชื่อรับพัสดุเสร็จสิ้น',
                text: 'กรุณาแจ้งเจ้าหน้าที่',
                confirmButtonText: 'ตกลง',
                confirmButtonColor: '#3085d6',
              }).then((result) => {
                if (result.isConfirmed) {
                  window.close();
                }
              });      
            } catch (error) {
              if (error.response && error.response.status === 422) {
                Swal.fire({
                  icon: 'warning',
                  title: 'พัสดุถูกรับไปแล้ว',
                  confirmButtonText: 'ตกลง',
                  confirmButtonColor: '#3085d6',
                });  
              } else {
                Swal.fire({
                  icon: 'error',
                  title: 'บันทึกข้อมูลไม่สำเร็จ',
                  text: 'กรุณาลองใหม่อีกครั้ง',
                  confirmButtonText: 'ตกลง',
                  confirmButtonColor: '#3085d6',
                }); 
                console.error("Error inserting data:", error);
              }
            }
          }
        }
      }, "image/png");
    }
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className={`${styles.adminPageBox} items-center justify-center flex bg-white mt-1`}>
          <select name="receiverType" value={dataDict.receiverType || ''} onChange={handleChange} className={`${styles.adminPageBtn} rounded-md font-sarabun`} required>
            <option value="" disabled>กรุณาเลือก</option>
            <option value="รับเอง" disabled={currentParams.get('sameName') === "false"}>รับเอง</option>
            <option value="รับแทน">รับแทน</option>
          </select>
          <input type="text" name="receiverName" value={dataDict.receiverName || ''} onChange={handleChange} placeholder="กรอกชื่อผู้รับพัสดุ" required className="font-sarabun input input-bordered ml-4" />
        </div>
        <div>
          <div className={`${styles.flexCenter}`}>
            <Canvas width={700} height={500} ref={canvasRef} />
          </div>
          <input type="submit" value={"บันทึกข้อมูล"} className={`${styles.adminPageBtn} mt-5 cursor-pointer rounded-md hover:bg-hoverBackground w-full`} />
        </div>
      </form>
    </div>
  );
};

export default PopupConfirm;
