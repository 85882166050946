import React, { useState, useRef } from "react";
import * as XLSX from "xlsx";
import axios from "axios";
import styles from "../style";
import { shippedIDTranferTextToID } from "../services";
import { getToken } from "../services";
import Swal from "sweetalert2";

const UploadFile = () => {
  const fileInputRef = useRef(null);
  const [parcelCompanyList, setParcelCompanyList] = useState([]);
  const [showparcelCompanyList, setshowParcelCompanyList] = useState([]);
  const headTable = [
    "ลำดับ",
    "ชื่อ-สกุลผู้รับ",
    "รหัสไปรษณีย์ปลายทาง",
    "ประเภทการจัดส่ง",
    "ประเภทพัสดุ",
    "ส่วน",
    "Cost Center",
    "GL",
    "Order",
    "เลขที่",
  ];

  const [sameTrack, setsameTrack] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [loading, setLoading] = useState(true);
  const [fileName, setFileName] = useState("No file chosen");

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file?.name) {
      setFileName(file?.name);
      if (file.name.split(".")[file.name.split(".").length - 1] === "xlsx") {
        const reader = new FileReader();
        console.log(file.name.split(".")[file.name.split(".").length - 1]);

        reader.onload = async (event) => {
          const data = event.target.result;
          const workbook = XLSX.read(data, { type: "binary" });
          const sheetName = workbook.SheetNames[0]; // เลือกชีทแรก

          // กำหนดคอลัมน์ที่ต้องการอ่าน
          const desiredColumns = ["B", "C", "D", "E", "F", "G", "H", "I", "J"];
          let endRow;

          const worksheet = workbook.Sheets[sheetName];
          const dataObject = {};

          for (let i = 3; ; i++) {
            const cellAddress = "B" + i;
            if (!worksheet[cellAddress]) {
              endRow = i - 1;
              break;
            }
          }

          for (let row = 3; row <= endRow; row++) {
            dataObject[row] = {};

            for (const col of desiredColumns) {
              const cellAddress = col + row;
              dataObject[row][col] = worksheet[cellAddress]
                ? worksheet[cellAddress].v
                : "";
            }
          }
          const allTrackNums = [];

          for (let num = 0; num <= endRow - 3; num++) {
            const trackNum = dataObject[num + 3]["J"];
            const shippedID = dataObject[num + 3]["D"];
            const parcelType = dataObject[num + 3]["E"];
            const department = dataObject[num + 3]["F"];
            const costCenter = dataObject[num + 3]["G"];
            const gl = dataObject[num + 3]["H"];
          
            if (!shippedID || !parcelType || !department || !costCenter || !gl || gl.length < 8) {
              Swal.fire({
                icon: 'warning',
                title: 'กรุณาอัพโหลดไฟล์ให้ถูกต้อง',
                text: 'พบค่าว่างในไฟล์ หรือจำนวนไม่ครบตามขั้นต่ำ',
                confirmButtonText: 'ตกลง',
                confirmButtonColor: '#3085d6',
              });
              setParcelCompanyList([]);
              setshowParcelCompanyList([]);
              break;
            } else {
              if (trackNum) {
                allTrackNums.push(trackNum);
              }
              const parcel = {
                receiverName: dataObject[num + 3]["B"] || "",
                receiverZip: parseInt(dataObject[num + 3]["C"].toString().substring(0, 5)),
                shippedID: shippedIDTranferTextToID(shippedID),
                parcelType: parcelType,
                department: department,
                costCenter: costCenter,
                gl: gl,
                order: dataObject[num + 3]["I"],
                trackNum: trackNum,
              };
              const showparcel = {
                receiverName: dataObject[num + 3]["B"] || "",
                receiverZip: parseInt(dataObject[num + 3]["C"].toString().substring(0, 5)),
                shippedID: shippedID,
                parcelType: parcelType,
                department: department,
                costCenter: costCenter,
                gl: gl,
                order: dataObject[num + 3]["I"],
                trackNum: trackNum,
              };
              setParcelCompanyList((prevParcelCompanyList) => [
                ...prevParcelCompanyList,
                parcel,
              ]);
              setshowParcelCompanyList((prevParcelCompanyList) => [
                ...prevParcelCompanyList,
                showparcel,
              ]);
            }
          }          
          setLoading(true);
          const check = await handleCheck(allTrackNums);
          if (check.message !== "Success") {
            setsameTrack(true);
            setLoading(false);
          } else {
            setsameTrack(false);
            setLoading(false);
          }
        };
        if (e.target.files.length !== 0) {
          reader.readAsBinaryString(file);
        }
      } else {
        // window.alert("กรุณาอัพโหลดไฟล์ให้ถูกต้อง (.xlsx)");
        Swal.fire({
          icon: 'warning',
          title: 'กรุณาอัพโหลดไฟล์ให้ถูกต้อง',
          text: '.xlsx เท่านั้น!',
          confirmButtonText: 'ตกลง',
          confirmButtonColor: '#3085d6',
        });        
        setParcelCompanyList([]);
        setshowParcelCompanyList([]);
      }
    }
  };

  const handlePopupToggleSave = () => {
    handleSubmit();
    setParcelCompanyList([]);
    setshowParcelCompanyList([]);
  };

  const handleSubmit = async () => {
    const token = getToken();
    const headers = {
      "Content-Type": "application/json",
      id_token: token.idToken,
      access_token: token.accessToken,
    };
    try {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/parcels/create-parcel-company`,
        { parcelCompanyList: parcelCompanyList },
        {
          headers: headers,
        }
      );
      // alert("บันทึกข้อมูลสำเร็จ!");
      Swal.fire({
        icon: 'success',
        title: 'บันทึกข้อมูลสำเร็จ!',
        confirmButtonText: 'ตกลง',
        confirmButtonColor: '#3085d6',
      });
      window.location.reload();
      console.log("Data inserted successfully");
    } catch (error) {
      // alert("บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง");
      Swal.fire({
        icon: 'error',
        title: 'บันทึกข้อมูลไม่สำเร็จ',
        text: 'กรุณาลองใหม่อีกครั้ง',
        confirmButtonText: 'ตกลง',
        confirmButtonColor: '#3085d6',
      });      
      console.error("Error inserting data:", error);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const clearfile = () => {
    setParcelCompanyList([]);
    setshowParcelCompanyList([]);
    setFileName("No file chosen")
    fileInputRef.current.value = "";
  };

  const trackNums = showparcelCompanyList.map((obj) => obj.trackNum);

  const handleCheck = async (allTrackNums) => {
    const token = getToken();
    const headers = {
      "Content-Type": "application/json",
      id_token: token.idToken,
      access_token: token.accessToken,
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/parcels/get-tracknum-company`,
        { parcelCompanyList: allTrackNums },
        { headers: headers }
      );
      console.log("Data inserted successfully", response.data);
      return response.data;
    } catch (error) {
      console.error("Error inserting data:", error);
    }
  };

  const atLeastTwoSame = trackNums.some((element, index) => {
    if (sameTrack) {
      return true;
    }
    if (element === "") {
      return false;
    }
    return trackNums.indexOf(element) !== index;
  });

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div>
      {/* <input
        ref={fileInputRef}
        className="cursor-pointer"
        type="file"
        onChange={handleFileChange}
      /> */}
      <div>
        <button
          type="button"
          className="px-4 py-2 bg-blue-500 text-white rounded"
          onClick={handleButtonClick}
        >
          เลือกไฟล์
        </button>
        <span className="ml-4">{fileName}</span>
        <input
          ref={fileInputRef}
          className="hidden"
          type="file"
          onChange={handleFileChange}
        />

        <button
          className={`${styles.defaultBtn} rounded-md mt-2 mr-2 border-green-600 text-green-600 hover:bg-green-100`}
          onClick={handlePopupToggleSave}
        >
          ยืนยัน
        </button>
        <button
          className={`${styles.defaultBtn} rounded-md mt-2 border-red-500 text-red-500 hover:bg-red-50`}
          onClick={() => clearfile()}
        >
          เคลียร์ไฟล์
        </button>
      </div>
      {isOpen && parcelCompanyList.length > 0 && (
        <div
          id="container"
          className="fixed inset-0 z-20 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center"
        >
          <div className="bg-white py-5 sm:px-12 px-8 rounded-xl max-h-[80vh] overflow-auto">
            {loading ? (
              <h3 className="font-bold font-sarabun text-2xl mb-6 flex justify-center">
                กำลังตรวจสอบเลขพัสดุ
              </h3>
            ) : (
              <h3 className="font-bold font-sarabun text-2xl mb-6 flex justify-center">
                ตรวจสอบความถูกต้องของข้อมูล
              </h3>
            )}
            {loading ? (
              <div className="flex justify-center">
                <svg
                  aria-hidden="true"
                  className="inline w-12 h-12 text-gray-200 animate-spin dark:text-borderLine fill-secondary"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <>
                {atLeastTwoSame ? (
                  <h3 className="font-bold font-sarabun text-2xl mb-6 flex justify-center text-red-500">
                    เลขพัสดุซ้ำ กรุณาตรวจสอบเลขพัสดุอีกครั้ง
                  </h3>
                ) : (
                  <div></div>
                )}
                <div>
                  <table className="table">
                    <thead>
                      <tr>
                        {headTable.map((value, index) => (
                          <th className="font-sarabun" key={index}>
                            {value}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody style={{ maxHeight: "200px", overflowY: "auto" }}>
                      {showparcelCompanyList.map((row, index) => (
                        <tr className="font-sarabun" key={index}>
                          <td>{index + 1}</td>
                          {Object.values(row).map((value, index) => (
                            <td key={index}>{value}</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <div className="flex justify-end">
                    <button
                      // className={`${styles.defaultBtn} rounded-md mt-2 mr-2 border-green-600 text-green-600 hover:bg-green-100`}
                      className={`${styles.defaultBtn} rounded-md mt-2 mr-2 ${
                        atLeastTwoSame
                          ? "bg-gray-400 text-gray-600 cursor-not-allowed"
                          : "border-green-600 text-green-600 hover:bg-green-100"
                      }`}
                      onClick={handleClose}
                      disabled={atLeastTwoSame}
                    >
                      ยืนยัน
                    </button>
                    <button
                      className={`${styles.defaultBtn} rounded-md mt-2 border-red-500 text-red-500 hover:bg-red-50`}
                      onClick={() => clearfile()}
                    >
                      ยกเลิก
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadFile;
