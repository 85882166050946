import styles from "../../style";
import React, { useState, useEffect } from "react";
import { shippedIDTranferIDToText, getPrice } from "../../services";
import axios from "axios";
import Swal from "sweetalert2";

function Table({
  parcelCompany,
  index,
  handleChangeInput,
  selectedRows,
  handleRowSelect,
}) {
  const classes = "py-4 border-b border-blue-gray-50 text-center";

  const isSelected = selectedRows.includes(parcelCompany.pcRunid);

  return (
    <tr key={index} className="font-sarabun">
      <td className={classes}>
        <input
          type="checkbox"
          checked={isSelected}
          onChange={() => handleRowSelect(parcelCompany.pcRunid)}
        />
      </td>

      <td className={classes}>
        <div>{parcelCompany?.receiverName}</div>
      </td>

      <td className={classes}>
        <div>{parcelCompany?.receiverZip}</div>
      </td>

      <td className={classes}>
        <div>{shippedIDTranferIDToText(parcelCompany?.shippedBy)}</div>
      </td>

      <td className={classes}>
        <div>{parcelCompany?.parcelType}</div>
      </td>

      <td className={classes}>
        <div>{parcelCompany?.department}</div>
      </td>

      <td className={classes}>
        <div>{parcelCompany?.costcenter}</div>
      </td>

      <td className={classes}>
        <div>{parcelCompany?.gl}</div>
      </td>

      <td className={classes}>
        <div>{parcelCompany?.order}</div>
      </td>

      <td className={classes}>
        {/* {parcelCompany.tracknum ? parcelCompany.tracknum : <input type="text" name="tracknum" value={parcelCompany?.tracknum || ''} onChange={(e) => handleChangeInput(parcelCompany.pcRunid, { tracknum: e.target.value })} className="input input-bordered w-20" />} */}
        <input
          type="text"
          name="tracknum"
          value={parcelCompany?.tracknum || ""}
          onChange={(e) =>
            handleChangeInput(parcelCompany.pcRunid, {
              tracknum: e.target.value,
            })
          }
          className="input input-bordered w-full"
        />
      </td>

      <td
        className="py-4 border-b border-blue-gray-50 text-center"
        style={{ width: "200px" }}
      >
        <div>
          <input
            type="number"
            name="weight"
            value={parcelCompany?.weight || ""}
            onChange={(e) => {
              if (parcelCompany.tracknum) {
                handleChangeInput(
                  parcelCompany.pcRunid,
                  { weight: e.target.value },
                  parcelCompany.shippedBy
                );
              } else {
                Swal.fire({
                  icon: "warning",
                  title: "กรุณากรอกเลขติดตามพัสดุก่อนจะกรอกข้อมูลอื่นๆ",
                  confirmButtonText: "ตกลง",
                  confirmButtonColor: "#3085d6",
                });
              }
            }}
            className="input input-bordered w-16"
          />
        </div>
      </td>
      <td
        className="py-4 border-b border-blue-gray-50 text-center"
        style={{ width: "200px" }}
      >
        <div>
          <input
            type="number"
            name="costplus"
            value={parcelCompany?.costplus || 0}
            onChange={(e) => {
              const value = e.target.value === "" ? 0 : Number(e.target.value);
              if (parcelCompany.tracknum) {
                handleChangeInput(
                  parcelCompany.pcRunid,
                  { costplus: value },
                  parcelCompany.shippedBy
                );
              } else {
                Swal.fire({
                  icon: "warning",
                  title: "กรุณากรอกเลขติดตามพัสดุก่อนจะกรอกข้อมูลอื่นๆ",
                  confirmButtonText: "ตกลง",
                  confirmButtonColor: "#3085d6",
                });
              }
            }}
            className="input input-bordered w-16"
          />
        </div>
      </td>
      <td
        className="py-4 border-b border-blue-gray-50 text-center"
        style={{ width: "200px" }}
      >
        <div>
          <input
            type="number"
            name="costreply"
            value={parcelCompany?.costreply || 0}
            onChange={(e) => {
              const value = e.target.value === "" ? 0 : Number(e.target.value);
              if (parcelCompany.tracknum) {
                handleChangeInput(
                  parcelCompany.pcRunid,
                  { costreply: value },
                  parcelCompany.shippedBy
                );
              } else {
                Swal.fire({
                  icon: "warning",
                  title: "กรุณากรอกเลขติดตามพัสดุก่อนจะกรอกข้อมูลอื่นๆ",
                  confirmButtonText: "ตกลง",
                  confirmButtonColor: "#3085d6",
                });
              }
            }}
            className="input input-bordered w-16"
          />
        </div>
      </td>
      <td
        className="py-4 border-b border-blue-gray-50 text-center"
        style={{ width: "200px" }}
      >
        <div>
          <input
            type="number"
            name="costgarantee"
            value={parcelCompany?.costgarantee || 0}
            onChange={(e) => {
              const value = e.target.value === "" ? 0 : Number(e.target.value);
              if (parcelCompany.tracknum) {
                handleChangeInput(
                  parcelCompany.pcRunid,
                  { costgarantee: value },
                  parcelCompany.shippedBy
                );
              } else {
                Swal.fire({
                  icon: "warning",
                  title: "กรุณากรอกเลขติดตามพัสดุก่อนจะกรอกข้อมูลอื่นๆ",
                  confirmButtonText: "ตกลง",
                  confirmButtonColor: "#3085d6",
                });
              }
            }}
            className="input input-bordered w-16"
          />
        </div>
      </td>

      <td className={classes}>
        <div>{parcelCompany?.fee}</div>
      </td>

      <td>
        <div>
          <button
            className={`${styles.adminPageBtn} rounded-md hover:bg-hoverBackground`}
            onClick={() => {
              if (parcelCompany.tracknum) {
                handleChangeInput(
                  parcelCompany.pcRunid,
                  { weight: 10 },
                  parcelCompany.shippedBy
                );
              } else {
                // alert(
                //   "Please fill in the tracking number field before entering the weight."
                // );
                Swal.fire({
                  icon: "warning",
                  title: "กรุณากรอกเลขติดตามพัสดุก่อนจะกรอกข้อมูลอื่นๆ",
                  confirmButtonText: "ตกลง",
                  confirmButtonColor: "#3085d6",
                });
              }
            }}
          >
            ราคาเริ่มต้น
          </button>
        </div>
      </td>
    </tr>
  );
}

const AddWeighParcel = () => {
  const PAGE_SIZE = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const TABLE_HEAD = [
    "ชื่อ-นามสกุลผู้รับ",
    "รหัสไปรษณีย์",
    "ประเภทการจัดส่ง",
    "ประเภทพัสดุ",
    "ส่วน",
    "COST",
    "GL",
    "Order",
    "เลขที่",
    "น้ำหนัก",
    "ค่าใช้จ่ายเพิ่มเติม",
    "ค่าตอบกลับ",
    "ค่าประกันสินค้าสูญหาย",
    "ค่าบริการ",
  ];
  const [parcelsCompany, setParcelsCompany] = useState([]);
  const [loading, setLoading] = useState(true);
  const currentDate = new Date();
  const formattedDate = `วันที่ ${currentDate.getDate()} เดือน ${
    currentDate.getMonth() + 1
  } ปี ${currentDate.getFullYear()}`;
  const [rates, setRate] = useState([]);

  useEffect(() => {
    const getParcelsCompany = async () => {
      try {
        const ParcelsCompanyList = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/parcels/get-parcel-admin-company`
        );
        setParcelsCompany(ParcelsCompanyList.data);
      } catch (error) {
        console.error("Error get parcels company", error);
      } finally {
        setLoading(false);
      }
    };
    getParcelsCompany();
  }, []);

  useEffect(() => {
    const getRate = async () => {
      try {
        const ratesList = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/postal-rates/get-rate`
        );
        setRate(ratesList.data);
      } catch (error) {
        console.error("Error get postal service rates", error);
      }
    };
    getRate();
  }, []);

  // const handleChangeInput = (
  //   pcRunid,
  //   newValue,
  //   shippedId,
  //   isMultiWeight = false
  // ) => {
  //   const calculateFee = (parcel) => {
  //     if (parcel.weight === undefined || parcel.weight === "") return 0;

  //     const basePrice = Number(getPrice(parcel.weight, shippedId, rates));
  //     const additionalCosts = [
  //       Number(parcel.costplus ?? 0),
  //       Number(parcel.costreply ?? 0),
  //       Number(parcel.costgarantee ?? 0),
  //     ].reduce((sum, cost) => sum + cost, 0);

  //     return basePrice + additionalCosts;
  //   };

  //   const updateParcel = (parcel) => {
  //     let updatedParcel = { ...parcel };

  //     if (isMultiWeight) {
  //       updatedParcel.weight = newValue.weight;
  //     } else {
  //       Object.keys(newValue).forEach((key) => {
  //         if (newValue[key] !== undefined) {
  //           updatedParcel[key] = newValue[key];
  //         }
  //       });
  //     }

  //     // ไม่คำนวณ fee ถ้ามีการอัปเดท tracknum
  //     if (!newValue.hasOwnProperty("tracknum")) {
  //       updatedParcel.fee = calculateFee(updatedParcel);
  //     }

  //     return updatedParcel;
  //   };

  //   const updatedParcels = parcelsCompany.map((parcel) => {
  //     if (isMultiWeight && selectedRows.includes(parcel.pcRunid)) {
  //       return updateParcel(parcel);
  //     } else if (!isMultiWeight && parcel.pcRunid === pcRunid) {
  //       return updateParcel(parcel);
  //     }
  //     return parcel;
  //   });

  //   setParcelsCompany(updatedParcels);
  // };

  const handleChangeInput = (
    pcRunid,
    newValue,
    shippedId,
    isMultiWeight = false
  ) => {
    const calculateFee = (parcel, weight, shippedBy) => {
      if (weight === undefined || weight === "") return 0;

      const basePrice = Number(getPrice(weight, shippedBy, rates));
      const additionalCosts = [
        Number(parcel.costplus ?? 0),
        Number(parcel.costreply ?? 0),
        Number(parcel.costgarantee ?? 0),
      ].reduce((sum, cost) => sum + cost, 0);

      return basePrice + additionalCosts;
    };

    const updateParcel = (parcel) => {
      let updatedParcel = { ...parcel };

      if (isMultiWeight) {
        updatedParcel.weight = newValue.weight;
      } else {
        Object.keys(newValue).forEach((key) => {
          if (newValue[key] !== undefined) {
            updatedParcel[key] = newValue[key];
          }
        });
      }

      // ไม่คำนวณ fee ถ้ามีการอัปเดท tracknum
      if (!newValue.hasOwnProperty("tracknum")) {
        updatedParcel.fee = calculateFee(
          updatedParcel,
          updatedParcel.weight,
          updatedParcel.shippedBy
        );
      }

      return updatedParcel;
    };

    const updatedParcels = parcelsCompany.map((parcel) => {
      if (isMultiWeight && selectedRows.includes(parcel.pcRunid)) {
        return updateParcel(parcel);
      } else if (!isMultiWeight && parcel.pcRunid === pcRunid) {
        return updateParcel(parcel);
      }
      return parcel;
    });

    setParcelsCompany(updatedParcels);
  };

  const handleUpdateParcel = async () => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}/parcels/update-parcel-weight`,
        { parcelsCompany }
      );
      // alert("บันทึกข้อมูลสำเร็จ!");
      // window.location.reload();
      Swal.fire({
        icon: "success",
        title: "บันทึกข้อมูลสำเร็จ!",
        confirmButtonText: "ตกลง",
        confirmButtonColor: "#3085d6",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
      console.log("Data inserted successfully");
    } catch (error) {
      if (error.response && error.response.status === 422) {
        // alert("เลขพัสดุถูกใช้งานแล้ว");
        Swal.fire({
          icon: "error",
          title: "บันทึกข้อมูลไม่สำเร็จ",
          text: "เลขพัสดุถูกใช้งานแล้ว",
          confirmButtonText: "ตกลง",
          confirmButtonColor: "#3085d6",
        });
      } else {
        // alert("บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง");
        Swal.fire({
          icon: "error",
          title: "บันทึกข้อมูลไม่สำเร็จ",
          text: "กรุณาลองใหม่อีกครั้ง",
          confirmButtonText: "ตกลง",
          confirmButtonColor: "#3085d6",
        });
        console.error("Error inserting data:", error);
      }
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const paginatedParcels = parcelsCompany.slice(
    (currentPage - 1) * PAGE_SIZE,
    currentPage * PAGE_SIZE
  );

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleRowSelect = (pcRunid) => {
    if (selectedRows.includes(pcRunid)) {
      setSelectedRows(selectedRows.filter((id) => id !== pcRunid));
    } else {
      setSelectedRows([...selectedRows, pcRunid]);
    }
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      setSelectedRows(paginatedParcels.map((parcel) => parcel.pcRunid));
    } else {
      setSelectedRows([]);
    }
  };

  return (
    <div>
      <p className={`${styles.pageHeading}`}>บันทึกน้ำหนักพัสดุ</p>
      <div className={`${styles.adminPageBox} mt-12`}>
        <div className="flex justify-between">
          <p className="font-sarabun font-bold text-2xl item-center">
            {formattedDate}
          </p>
          <button
            className={`${styles.adminPageBtn} rounded-md hover:bg-hoverBackground`}
            onClick={() => handleUpdateParcel()}
          >
            บันทึกข้อมูล
          </button>
        </div>
        {loading ? (
          <div
            role="status"
            className="flex items-center justify-center h-screen"
          >
            <svg
              aria-hidden="true"
              className="inline w-12 h-12 text-gray-200 animate-spin dark:text-borderLine fill-secondary"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          <div className="w-full px-0 overflow-x-auto">
            <table className="w-full min-w-max table-fixed mt-4 text-center overflow-x: scroll">
              <thead>
                <tr>
                  <th className="border-y-2 border-blue-gray-100 bg-blue-gray-50/50 p-4">
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={handleSelectAll}
                    />
                  </th>
                  {TABLE_HEAD.map((head, index) => {
                    const isNumericHeader = head === "เลขที่";
                    const headerStyle = isNumericHeader
                      ? { width: "170px" }
                      : {};

                    return (
                      <th
                        key={index}
                        className="border-y-2 border-blue-gray-100 bg-blue-gray-50/50 p-4"
                        style={headerStyle}
                      >
                        <div className="font-sarabun font-bold leading-none opacity-70">
                          {head}
                        </div>
                      </th>
                    );
                  })}
                  <th className="border-y-2 border-blue-gray-100 bg-blue-gray-50/50 p-4">
                    {selectedRows.length > 0 && (
                      <div className="my-4">
                        <input
                          type="number"
                          name="multiweight"
                          onChange={(e) => {
                            const newWeight = e.target.value;
                            const validParcels = selectedRows.every(
                              (selectedRow) =>
                                parcelsCompany.find(
                                  (parcel) => parcel.pcRunid === selectedRow
                                ).tracknum
                            );

                            if (validParcels) {
                              handleChangeInput(
                                null,
                                { weight: newWeight },
                                null, // ไม่ต้องส่ง shippedBy ที่นี่
                                true
                              );
                            } else {
                              Swal.fire({
                                icon: "warning",
                                title:
                                  "กรุณากรอกเลขติดตามพัสดุก่อนจะกรอกข้อมูลอื่นๆ",
                                confirmButtonText: "ตกลง",
                                confirmButtonColor: "#3085d6",
                              });
                            }
                          }}
                          className="input input-bordered w-16"
                        />
                      </div>
                    )}
                  </th>
                </tr>
              </thead>

              <tbody>
                {paginatedParcels?.map((parcelCompany, index) => (
                  <Table
                    key={index}
                    parcelCompany={parcelCompany}
                    handleChangeInput={handleChangeInput}
                    selectedRows={selectedRows}
                    handleRowSelect={handleRowSelect}
                  />
                ))}
              </tbody>
            </table>
          </div>
        )}

        <footer className="flex items-center justify-between p-4 font-sarabun text-slate-500">
          <div>
            หน้าที่ {currentPage} จาก{" "}
            {Math.ceil(parcelsCompany.length / PAGE_SIZE)}
          </div>
          <div>
            <button
              className={`${styles.permissionPageBtn} rounded-md hover:bg-hoverBackground duration-500`}
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              ย้อนกลับ
            </button>
            &nbsp;&nbsp;
            <button
              className={`${styles.permissionPageBtn} rounded-md hover:bg-hoverBackground duration-500`}
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={
                currentPage === Math.ceil(parcelsCompany.length / PAGE_SIZE)
              }
            >
              ถัดไป
            </button>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default AddWeighParcel;
