import { STATUS } from '../utils';

export function getColorStatusParcel( parcel ) {
    const colorStatus = `${parcel.status === STATUS['0'].status['th'] && STATUS['0'].style}
                        ${parcel.status === STATUS['1'].status['th'] && STATUS['1'].style}
                        ${parcel.status === STATUS['2'].status['th'] && STATUS['2'].style}
                        ${parcel.status === STATUS['3'].status['th'] && STATUS['3'].style}
                        ${parcel.status === STATUS['4'].status['th'] && STATUS['4'].style}`;

    return colorStatus ;
}

export function getColorStatusText( text ) {
    const colorStatus = `${text === STATUS['0'].status['th'] && STATUS['0'].style}
                        ${text === STATUS['1'].status['th'] && STATUS['1'].style}
                        ${text === STATUS['2'].status['th'] && STATUS['2'].style}
                        ${text === STATUS['3'].status['th'] && STATUS['3'].style}
                        ${text === STATUS['4'].status['th'] && STATUS['4'].style}`;

    return colorStatus ;
}
