import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdKeyboardArrowUp } from "react-icons/md";
import styles from "../../style";
import { GiCardboardBoxClosed } from "react-icons/gi";
import { getToken } from "../../services";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import { Loading } from "../../components";
import Swal from "sweetalert2";

const PackageDetail = ({ details }) => (
  <Box
    sx={{ border: "1px solid #ddd", borderRadius: "8px", overflow: "hidden" }}
  >
    <Box
      sx={{
        display: "flex",
        backgroundColor: "#f2f2f2",
        padding: "5px",
        borderBottom: "1px solid #ddd",
        alignItems: "center",
      }}
    >
      <Table
        size="small"
        aria-label="package details"
        sx={{
          "& .MuiTableCell-root": {
            fontFamily: "Sarabun",
          },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell>ลำดับ</TableCell>
            <TableCell>ผู้รับ</TableCell>
            <TableCell>รหัสไปรษณีย์</TableCell>
            <TableCell>ประเภทการจัดส่ง</TableCell>
            <TableCell>ประเภทพัสดุ</TableCell>
            <TableCell>Cost Center</TableCell>
            <TableCell>GL</TableCell>
            <TableCell>Order</TableCell>
            <TableCell>เลขที่</TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            backgroundColor: "#ffffff",
          }}
        >
          {details.map((detail, index) => (
            <TableRow key={index}>
              <TableCell>{index + 1}</TableCell>
              <TableCell>{detail.recipient}</TableCell>
              <TableCell>{detail.postalCode}</TableCell>
              <TableCell>{detail.deliveryType}</TableCell>
              <TableCell>{detail.packageType}</TableCell>
              <TableCell>{detail.costCenter}</TableCell>
              <TableCell>{detail.gl}</TableCell>
              <TableCell>{detail.order}</TableCell>
              <TableCell>{detail.number}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  </Box>
);

const PackageInfo = ({
  sender,
  dateTime,
  packageCount,
  groupParcel,
  details,
}) => {
  const [open, setOpen] = useState(false);
  const [isPopupVisibleApprove, setPopupVisibilityApprove] = useState(false);
  const [isPopupVisibleNotApprove, setPopupVisibilityNotApprove] =
    useState(false);
  const handlePopupToggleApprove = () => {
    setPopupVisibilityApprove(!isPopupVisibleApprove);
  };
  const handlePopupToggleNotApprove = () => {
    setPopupVisibilityNotApprove(!isPopupVisibleNotApprove);
  };
  const [comment, setComment] = useState("");
  const handleChange = (e) => {
    setComment(e.target.value);
  };

  const handleApproveParcel = () => {
    const token = getToken();
    const headers = {
      "Content-Type": "application/json",
      id_token: token.idToken,
      access_token: token.accessToken,
    };
    try {
      axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/parcels/Approve`,
        {
          parcalId: groupParcel,
          parcalStatus: "Approve",
          parcalComment: comment,
        },
        {
          headers: headers,
        }
      );

      Swal.fire({
        title: "อนุมัติสำเร็จ!",
        icon: "success",
        confirmButtonText: "ตกลง",
        showConfirmButton: true,
        confirmButtonColor: "#3085d6",
      }).then((result) => {
        if (result.isConfirmed) {
          console.log("Data inserted successfully");
          window.location.href = window.location.href + (window.location.href.includes('?') ? '&' : '?') + 't=' + Date.now();
          // window.location.reload(true);
        }
      });
    } catch (error) {
      Swal.fire({
        title: "ผิดพลาด!",
        text: "เกิดข้อผิดพลาดในการบันทึกข้อมูล!",
        icon: "error",
        confirmButtonText: "ตกลง",
        customClass: {
          confirmButton: `${styles.customconfirmbutton}`,
        },
      });
      console.error("Error inserting data", error);
    }
  };

  const handleNotApproveParcel = () => {
    const token = getToken();
    const headers = {
      "Content-Type": "application/json",
      id_token: token.idToken,
      access_token: token.accessToken,
    };
    try {
      axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/parcels/Approve`,
        {
          parcalId: groupParcel,
          parcalStatus: "Not Approve",
          parcalComment: comment,
        },
        {
          headers: headers,
        }
      );

      Swal.fire({
        title: "ไม่อนุมัติสำเร็จ!",
        icon: "success",
        confirmButtonText: "ตกลง",
        showConfirmButton: true,
        confirmButtonColor: "#3085d6",
      }).then((result) => {
        if (result.isConfirmed) {
          console.log("Data inserted successfully");
          window.location.reload();
        }
      });
    } catch (error) {
      Swal.fire({
        title: "ผิดพลาด!",
        text: "เกิดข้อผิดพลาดในการบันทึกข้อมูล!",
        icon: "error",
        confirmButtonText: "ตกลง",
        customClass: {
          confirmButton: `${styles.customconfirmbutton}`,
        },
      });
      console.error("Error inserting data", error);
    }
  };

  return (
    <>
      <Box
        sx={{
          border: "1px solid #ddd",
          borderRadius: "8px",
          overflow: "hidden",
        }}
        className="font-sarabun"
      >
        <Box
          sx={{
            display: "flex",
            backgroundColor: "#f2f2f2",
            padding: "5px",
            borderBottom: "1px solid #ddd",
            alignItems: "center",
          }}
        >
          <IconButton onClick={() => setOpen(!open)}>
            {open ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
          </IconButton>
          <Box sx={{ flex: 2, display: "flex", justifyContent: "left" }}>
            ชื่อผู้ส่ง : {sender}
          </Box>
          <Box sx={{ flex: 2, display: "flex", justifyContent: "left" }}>
            วันที่ - เวลา ที่ส่ง : {dateTime}
          </Box>
          <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
            จำนวนพัสดุ : {packageCount}
          </Box>
          <Box sx={{ flex: 1, display: "flex", justifyContent: "right" }}>
            <Button
              variant="contained"
              sx={{
                mr: 1,
                backgroundColor: "#009B9F",
                "&:hover": {
                  backgroundColor: "#007B7F",
                },
                borderRadius: "10px",
                fontFamily: "Sarabun",
              }}
              onClick={handlePopupToggleApprove}
            >
              อนุมัติ
            </Button>
          </Box>
          <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              sx={{
                mr: 1,
                backgroundColor: "#FC5353",
                "&:hover": {
                  backgroundColor: "#FC5353",
                },
                borderRadius: "10px",
                fontFamily: "Sarabun",
              }}
              onClick={handlePopupToggleNotApprove}
            >
              ไม่อนุมัติ
            </Button>
          </Box>
        </Box>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <div className="flex justify-center items-center flex-col overflow-auto font-sarabun">
            <br></br>
            <p>รายการพัสดุทั้งหมด</p>
          </div>
          <Box sx={{ padding: 2 }}>
            <PackageDetail details={details} />
          </Box>
        </Collapse>
      </Box>
      {isPopupVisibleApprove && (
        <div
          id="container"
          className="z-10 fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center"
        >
          <div
            className="bg-white py-5 sm:px-12 px-8 rounded-xl"
            style={{ width: "500px" }}
          >
            <h3 className="font-bold font-sarabun text-2xl mb-6 flex justify-center">
              ยืนยันการอนุมัติ
            </h3>
            <p className={`${styles.popupTextBody}`}>
              ผู้ส่ง : <label className="font-bold">{sender}</label>
            </p>
            <p className={`${styles.popupTextBody}`}>
              วันที่ - เวลาที่ส่ง :{" "}
              <label className="font-bold">{dateTime}</label>
            </p>
            <p className={`${styles.popupTextBody}`}>
              จำนวนพัสดุ : <label className="font-bold">{packageCount}</label>
            </p>
            <br />
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                sx={{
                  mr: 1,
                  backgroundColor: "#009B9F",
                  "&:hover": {
                    backgroundColor: "#007B7F",
                  },
                  borderRadius: "10px",
                  fontFamily: "Sarabun",
                }}
                onClick={handleApproveParcel}
              >
                ยืนยัน
              </Button>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#8C8C8C",
                  "&:hover": {
                    backgroundColor: "#8C8C8C",
                  },
                  borderRadius: "10px",
                  fontFamily: "Sarabun",
                }}
                onClick={handlePopupToggleApprove}
              >
                ยกเลิก
              </Button>
            </Box>
          </div>
        </div>
      )}
      {isPopupVisibleNotApprove && (
        <div
          id="container"
          className="z-10 fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center"
        >
          <div
            className="bg-white py-5 sm:px-12 px-8 rounded-xl"
            style={{ width: "500px" }}
          >
            <h3 className="font-bold font-sarabun text-2xl mb-6 flex justify-center">
              ยืนยันการไม่อนุมัติ
            </h3>
            <p className={`${styles.popupTextBody}`}>
              ผู้ส่ง : <label className="font-bold">{sender}</label>
            </p>
            <p className={`${styles.popupTextBody}`}>
              วันที่ - เวลาที่ส่ง :{" "}
              <label className="font-bold">{dateTime}</label>
            </p>
            <p className={`${styles.popupTextBody}`}>
              จำนวนพัสดุ : <label className="font-bold">{packageCount}</label>
            </p>
            <br />
            <input
              type="text"
              name="Comment"
              placeholder="เหตุผลที่ไม่อนุมัติ"
              className="input input-bordered w-full"
              required
              onChange={handleChange}
            />
            <br />
            <br />
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                sx={{
                  mr: 1,
                  backgroundColor: "#FC5353",
                  "&:hover": {
                    backgroundColor: "#FC5353",
                  },
                  borderRadius: "10px",
                  fontFamily: "Sarabun",
                }}
                onClick={handleNotApproveParcel}
                disabled={!comment}
              >
                ยืนยัน
              </Button>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#8C8C8C",
                  "&:hover": {
                    backgroundColor: "#8C8C8C",
                  },
                  borderRadius: "10px",
                  fontFamily: "Sarabun",
                }}
                onClick={handlePopupToggleNotApprove}
              >
                ยกเลิก
              </Button>
            </Box>
          </div>
        </div>
      )}
    </>
  );
};

const Approve = () => {
  const { instance } = useMsal();
  const [loading, setLoading] = useState(true);
  const [packages, SetPackages] = useState([]);

  useEffect(() => {
    const token = getToken();
    const headers = {
      "Content-Type": "application/json",
      id_token: token.idToken,
      access_token: token.accessToken,
    };

    const fetchData = async () => {
      try {
        const resNormalParcel = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/parcels/approval`,
          { headers: headers }
        );
        SetPackages(resNormalParcel.data);
      } catch (error) {
        console.error("Error can't get data: ", error);
        instance.logoutRedirect();
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [instance]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <p className={`${styles.pageHeading}`}>รายการส่งพัสดุรออนุมัติ</p>
          {packages.length > 0 ? (
            <Box sx={{ padding: 2 }}>
              {packages?.map((pkg, index) => (
                <PackageInfo key={index} {...pkg} />
              ))}
            </Box>
          ) : (
            <div className="flex justify-center items-center flex-col overflow-auto">
              <GiCardboardBoxClosed style={{ fontSize: "300px" }} />
              <p style={{ fontSize: "30px" }} className="font-sarabun">
                ไม่มีพัสดุที่ต้องรออนุมัติ
              </p>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Approve;
