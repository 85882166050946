import { useState } from "react";
import Barcode from "react-barcode";
import styles from "../style";
import DateFormat from "./DateFormat";
import { STATUS } from "../utils";
import { TbTruckDelivery } from "react-icons/tb";
import { MdAssignmentAdd } from "react-icons/md";
import { MdModeEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import Swal from "sweetalert2";
import axios from 'axios'
import { getToken } from "../services";

function formatDate(dateString) {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
}

function Cards({ item, index, pageTypeBtn }) {
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const classStatus = `${
    item.status === STATUS["0"].status["th"] && STATUS["0"].style
  } ${item.status === STATUS["1"].status["th"] && STATUS["1"].style} ${
    item.status === STATUS["2"].status["th"] && STATUS["2"].style
  } ${item.status === STATUS["3"].status["th"] && STATUS["3"].style} ${
    item.status === STATUS["4"].status["th"] && STATUS["4"].style
  } font-bold`;

  const handlePopupToggle = () => {
    setPopupVisibility(!isPopupVisible);
  };

  function openPopup(id) {
    const temp =
      `${process.env.REACT_APP_HOST_POPUP}/employee/Edit?id=`+encodeURIComponent(id)
    window.location.href = temp
  }

  const deleteParcel = (id) => {
    const token = getToken();
    const headers = {
        "Content-Type": "application/json",
        id_token: token.idToken,
        access_token: token.accessToken,
    };
    try {
        Swal.fire({
            icon: 'warning',
            title: 'ต้องการลบพัสดุไหม!',
            confirmButtonText: 'ตกลง',
            confirmButtonColor: '#3085d6',
            cancelButtonText: 'ยกเลิก',
            cancelButtonColor: "#FE0000",
            showCancelButton: true,
          }).then((result) => {
            if (result.isConfirmed) {
              axios.delete(`${process.env.REACT_APP_API_BASE_URL}/parcels/delete-parcel-by-id?id=`+ encodeURIComponent(id), {headers: headers});
              Swal.fire({
                icon: 'success',
                title: 'ลบข้อมูลสำเร็จ!',
                confirmButtonText: 'ตกลง',
                confirmButtonColor: '#3085d6',
              }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload();
                }
              }); 
            }
          });
        console.log('Data inserted successfully');
    } catch (error) {
        // alert("บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง");
        Swal.fire({
            icon: 'error',
            title: 'บันทึกข้อมูลไม่สำเร็จ',
            text: 'กรุณาลองใหม่อีกครั้ง',
            confirmButtonText: 'ตกลง',
            confirmButtonColor: '#3085d6',
          }); 
        console.error('Error inserting data:', error);
    }
};

  return (
    <div key={index} className={`${styles.cardStyle}`}>
      <div className={`${styles.cardPadding}`}>
        {pageTypeBtn ? (
          <>
            {item?.kind === "ภายนอกบริษัท" ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  justifyContent: "flex-start",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid",
                    borderColor: "borderLine",
                    borderRadius: "0.75rem",
                    padding: "0.4rem",
                  }}
                >
                  <TbTruckDelivery style={{ marginRight: "0.5rem" }} />
                  <p style={{ margin: 0 }} className="font-sarabun">
                    ภายนอกบริษัท
                  </p>
                </span>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  justifyContent: "flex-start",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid",
                    borderColor: "borderLine",
                    borderRadius: "0.75rem",
                    padding: "0.4rem",
                  }}
                >
                  <MdAssignmentAdd style={{ marginRight: "0.5rem" }} />
                  <p style={{ margin: 0 }} className="font-sarabun">
                    ภายในบริษัท
                  </p>
                </span>
              </div>
            )}
            <p className={`${styles.cardTextHead}`}>
              ชื่อผู้ส่ง: {item?.senderFullname}
            </p>
          </>
        ) : (
          <>
            {item?.approveLv1 ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid",
                    borderColor: "borderLine",
                    borderRadius: "0.75rem",
                    padding: "0.4rem",
                  }}
                >
                  <TbTruckDelivery style={{ marginRight: "0.5rem" }} />
                  <p style={{ margin: 0 }} className="font-sarabun">
                    ในนามบริษัท
                  </p>
                </span>
                {item?.canEdit && item?.canEditLevel2 ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                      }}
                    >
                      <button
                        onClick={() => openPopup(item?.pcRunid)}
                        style={{
                          background: "none",
                          border: "none",
                          padding: 0,
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          marginRight: "1rem"
                        }}
                      >
                        <MdModeEdit size={25} />
                      </button>
                      <button
                        onClick={() => deleteParcel(item?.pcRunid)}
                        style={{
                          background: "none",
                          border: "none",
                          padding: 0,
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          marginRight: "1rem"
                        }}
                      >
                        <MdDelete size={25} />
                      </button>
                    </div>
                  </>
                ) : null}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  justifyContent: "flex-start",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid",
                    borderColor: "borderLine",
                    borderRadius: "0.75rem",
                    padding: "0.4rem",
                  }}
                >
                  <MdAssignmentAdd style={{ marginRight: "0.5rem" }} />
                  <p style={{ margin: 0 }} className="font-sarabun">
                    ภายในหน่วยงาน
                  </p>
                </span>
              </div>
            )}

            <p className={`${styles.cardTextHead}`}>
              ชื่อผู้รับ: {item?.receiverFullname}
            </p>
          </>
        )}
        {item.tracknum && (
          <div className="justify-center flex">
            <Barcode
              value={item?.tracknum}
              width={2}
              height={50}
              displayValue={false}
            />
          </div>
        )}
        <p className={`${styles.cardTextBody}`}>
          หมายเลขติดตามพัสดุ:{" "}
          <label className="font-bold">{item?.tracknum}</label>
        </p>
        <p className={`${styles.cardTextBody}`}>
          สถานะ: <label className={classStatus}>{item?.status}</label>
        </p>
        {item?.status === "ไม่อนุมัติ" ? (
          <p className={`${styles.cardTextBody}`}>
            เหตุผลที่ไม่อนุมัติ:{" "}
            <label className="font-bold">{item.reasonNotApprove}</label>
          </p>
        ) : null}
        <p className={`${styles.cardTextBody}`}>
          วันที่:{" "}
          <label className="font-bold">
            {item?.dateCurrent ? formatDate(item.dateCurrent) : ""}
          </label>
        </p>
        {item?.approveLv1 ? null : (
          <p className={`${styles.cardTextBody}`}>
            โรงงานปลายทาง:{" "}
            <label className="font-bold">{item.workAreaTo}</label>
          </p>
        )}

        <p className={`${styles.cardTextBody}`}>
          ประเภท: <label className="font-bold">{item.type}</label>
        </p>
        {!pageTypeBtn &&
        item?.status === STATUS["3"].status["th"] &&
        item.type ? (
          <div>
            <p className={`${styles.cardTextBody}`}>
              ผู้รับ: <label className="font-bold">{item.receivedName}</label>
            </p>
            <p className={`${styles.cardTextBody}`}>
              ประเภทการรับ:{" "}
              <label className="font-bold">{item.receivedType}</label>
            </p>
          </div>
        ) : (
          ""
        )}
        <button
          className={`${styles.defaultBtn} rounded-md mt-2 ${
            pageTypeBtn
              ? `text-secondary border-secondary hover:bg-hoverBackground`
              : `text-orange-600 border-orangeSKC hover:bg-orange-50`
          }`}
          onClick={handlePopupToggle}
        >
          ดูรายละเอียดเพิ่มเติม
        </button>
        {isPopupVisible && (
          <div
            id="container"
            className="z-10 fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center"
          >
            <div
              className="bg-white py-5 sm:px-12 px-8 rounded-xl overflow-y-auto"
              style={{ width: "500px", height: "500px" }}
            >
              <h3 className="font-bold font-sarabun text-2xl mb-6 flex justify-center">
                ข้อมูลพัสดุ
              </h3>

              <div className="mb-3">
                {pageTypeBtn ? (
                  <h1 className={`${styles.popupTextHead}`}>
                    ข้อมูลผู้ส่งพัสดุ
                  </h1>
                ) : (
                  <>
                    {item?.approveLv1 && (
                      <div
                        style={{
                          border: "1px solid #ddd",
                          borderRadius: "8px",
                          overflow: "hidden",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            backgroundColor: "#f2f2f2",
                            padding: "12px",
                            borderBottom: "1px solid #ddd",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              flex: 1,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            ลำดับอนุมัติ
                          </div>
                          <div
                            style={{
                              flex: 2,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            ชื่อ - นามสกุล
                          </div>
                          <div
                            style={{
                              flex: 1,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            สถานะ
                          </div>
                        </div>

                        {/* Row 1 */}
                        <div
                          style={{
                            display: "flex",
                            padding: "12px",
                            borderBottom: "1px solid #ddd",
                          }}
                        >
                          <div
                            style={{
                              flex: 1,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            1
                          </div>
                          <div
                            style={{
                              flex: 2,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {pageTypeBtn
                              ? item?.approveLv1Name
                              : item?.approveLv1Name}
                          </div>
                          <div
                            style={{
                              flex: 1,
                              display: "flex",
                              justifyContent: "center",
                            }}
                            className={`font-bold ${
                              item?.approveLv1Status === "อนุมัติ"
                                ? "text-green-600"
                                : item?.approveLv1Status === "รออนุมัติ"
                                ? "text-orange-500"
                                : "text-red-500"
                            }`}
                          >
                            {pageTypeBtn
                              ? item?.approveLv1Status
                              : item?.approveLv1Status}
                          </div>
                        </div>

                        {/* Row 2 */}
                        <div
                          style={{
                            display: "flex",
                            padding: "12px",
                          }}
                        >
                          <div
                            style={{
                              flex: 1,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            2
                          </div>
                          <div
                            style={{
                              flex: 2,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {pageTypeBtn
                              ? item?.approveLv2Name
                              : item?.approveLv2Name}
                          </div>
                          <div
                            style={{
                              flex: 1,
                              display: "flex",
                              justifyContent: "center",
                            }}
                            className={`font-bold ${
                              item?.approveLv2Status === "อนุมัติ"
                                ? "text-green-600"
                                : item?.approveLv2Status === "รออนุมัติ"
                                ? "text-orange-500"
                                : "text-red-500"
                            }`}
                          >
                            {pageTypeBtn
                              ? item?.approveLv2Status
                              : item?.approveLv2Status}
                          </div>
                        </div>
                      </div>
                    )}
                    <br></br>
                    <h1 className={`${styles.popupTextHead}`}>
                      ข้อมูลผู้รับพัสดุ
                    </h1>
                  </>
                )}
                {item.tracknum && (
                  <div className="flex justify-center">
                    <Barcode
                      value={item?.tracknum}
                      width={1.5}
                      height={50}
                      displayValue={false}
                    />
                  </div>
                )}
                <p className={`${styles.popupTextBody}`}>
                  หมายเลขติดตามพัสดุ:{" "}
                  <label className="font-bold">
                    {item?.tracknum === "" ? "-" : item?.tracknum}
                  </label>
                </p>
                <p className={`${styles.popupTextBody}`}>
                  ชื่อ-นามสกุล:{" "}
                  <label className="font-bold">
                    {pageTypeBtn
                      ? [item?.senderFullname]
                      : [item?.receiverFullname]}
                  </label>
                </p>
                <p className={`${styles.popupTextBody}`}>
                  ประเภท: <label className="font-bold">{item?.type}</label>
                </p>
                {item?.approveLv1 ? (
                  <>
                    <p className={`${styles.popupTextBody}`}>
                      รหัสไปรษณีย์ปลายทาง:{" "}
                      <label className="font-bold">{item?.receiverZip}</label>
                    </p>
                    <p className={`${styles.popupTextBody}`}>
                      ประเภทการจัดส่ง:{" "}
                      <label className="font-bold">
                        {item?.shippedBy || "-"}
                      </label>
                    </p>
                    <p className={`${styles.popupTextBody}`}>
                      GL: <label className="font-bold">{item?.gl || "-"}</label>
                    </p>
                    <p className={`${styles.popupTextBody}`}>
                      Cost Center:{" "}
                      <label className="font-bold">
                        {item?.costcenter || "-"}
                      </label>
                    </p>
                    <p className={`${styles.popupTextBody}`}>
                      Order:{" "}
                      <label className="font-bold">{item?.order || "-"}</label>
                    </p>
                  </>
                ) : (
                  <>
                    <p className={`${styles.popupTextBody}`}>
                      รหัสพนักงาน:{" "}
                      <label className="font-bold">
                        {pageTypeBtn
                          ? item?.senderEid || "-"
                          : item?.receiverEid || "-"}
                      </label>
                    </p>
                    <p className={`${styles.popupTextBody}`}>
                      อีเมล:{" "}
                      <label className="font-bold">
                        {pageTypeBtn
                          ? item?.senderEmail || "-"
                          : item?.receiverEmail || "-"}
                      </label>
                    </p>
                    <p className={`${styles.popupTextBody}`}>
                      สังกัดหน่วยงาน:{" "}
                      <label className="font-bold">
                        {pageTypeBtn
                          ? item?.senderSection === "None"
                            ? "-"
                            : item?.senderSection || "-"
                          : item?.receiverSection === "None"
                          ? "-"
                          : item?.receiverSection || "-"}
                      </label>
                    </p>
                    <p className={`${styles.popupTextBody}`}>
                      ส่วน:{" "}
                      <label className="font-bold">
                        {pageTypeBtn
                          ? item?.senderDepartment === "None"
                            ? "-"
                            : item?.senderDepartment || "-"
                          : item?.senderDepartment === "None"
                          ? "-"
                          : item?.receiverDepartment || "-"}
                      </label>
                    </p>
                    <p className={`${styles.popupTextBody}`}>
                      ฝ่าย:{" "}
                      <label className="font-bold">
                        {pageTypeBtn
                          ? item?.senderDivision === "None"
                            ? "-"
                            : item?.senderDivision || "-"
                          : item?.receiverDivision === "None"
                          ? "-"
                          : item?.receiverDivision || "-"}
                      </label>
                    </p>
                  </>
                )}
              </div>

              {item?.status !== "รออนุมัติ" ? (
                <div className="mb-3">
                  <h1 className={`${styles.popupTextHead}`}>สถานะพัสดุ</h1>
                  <DateFormat item={item} />
                </div>
              ) : (
                <div></div>
              )}
              <div className="flex justify-end">
                <button
                  className={`${styles.defaultBtn} rounded-md mt-2 ${
                    pageTypeBtn
                      ? `text-secondary border-secondary hover:bg-hoverBackground`
                      : `text-orange-600 border-orangeSKC hover:bg-orange-50`
                  }`}
                  onClick={handlePopupToggle}
                >
                  ปิด
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Cards;
