import { useEffect, useRef } from 'react';

export function useOnDraw(onDraw) {

    const canvasRef = useRef(null);
    const isDrawingRef = useRef(false);
    const mouseMoveListenerRef = useRef(null);
    const mouseDownListenerRef = useRef(null);
    const mouseUpListenerRef = useRef(null);
    const touchMoveListenerRef = useRef(null);
    const touchStartListenerRef = useRef(null);
    const touchEndListenerRef = useRef(null);
    const prevPointRef = useRef(null);

    useEffect(() => {
        return () => {
            if (mouseMoveListenerRef.current) {
                window.removeEventListener("mousemove", mouseMoveListenerRef.current);
            }
            if (mouseUpListenerRef.current) {
                window.removeEventListener("mouseup", mouseUpListenerRef.current);
            }
            if (touchMoveListenerRef.current) {
                window.removeEventListener("touchmove", touchMoveListenerRef.current);
            }
            if (touchEndListenerRef.current) {
                window.removeEventListener("touchend", touchEndListenerRef.current);
            }
        };
    }, []);

    function setCanvasRef(ref) {
        if (!ref) return;
        if (canvasRef.current) {
            canvasRef.current.removeEventListener("mousedown", mouseDownListenerRef.current);
            canvasRef.current.removeEventListener("touchstart", touchStartListenerRef.current);
        }
        canvasRef.current = ref;
        initMouseListeners();
        initTouchListeners();
    }

    function initMouseListeners() {
        const mouseMoveListener = (e) => {
            if (isDrawingRef.current) {
                const point = computePointInCanvas(e.clientX, e.clientY);
                const ctx = canvasRef.current.getContext('2d');
                if (onDraw) onDraw(ctx, point, prevPointRef.current);
                prevPointRef.current = point;
            }
        };
        mouseMoveListenerRef.current = mouseMoveListener;
        window.addEventListener("mousemove", mouseMoveListener);

        const mouseUpListener = () => {
            isDrawingRef.current = false;
            prevPointRef.current = null;
        };
        mouseUpListenerRef.current = mouseUpListener;
        window.addEventListener("mouseup", mouseUpListener);

        const mouseDownListener = () => {
            isDrawingRef.current = true;
        };
        mouseDownListenerRef.current = mouseDownListener;
        canvasRef.current.addEventListener("mousedown", mouseDownListener);
    }

    function initTouchListeners() {
        const touchMoveListener = (e) => {
            if (isDrawingRef.current && e.touches.length === 1) {
                const touch = e.touches[0];
                const point = computePointInCanvas(touch.clientX, touch.clientY);
                const ctx = canvasRef.current.getContext('2d');
                if (onDraw) onDraw(ctx, point, prevPointRef.current);
                prevPointRef.current = point;
            }
        };
        touchMoveListenerRef.current = touchMoveListener;
        window.addEventListener("touchmove", touchMoveListener);

        const touchEndListener = () => {
            isDrawingRef.current = false;
            prevPointRef.current = null;
        };
        touchEndListenerRef.current = touchEndListener;
        window.addEventListener("touchend", touchEndListener);

        const touchStartListener = (e) => {
            if (e.touches.length === 1) {
                isDrawingRef.current = true;
            }
        };
        touchStartListenerRef.current = touchStartListener;
        canvasRef.current.addEventListener("touchstart", touchStartListener);
    }

    function computePointInCanvas(clientX, clientY) {
        if (canvasRef.current) {
            const boundingRect = canvasRef.current.getBoundingClientRect();
            return {
                x: clientX - boundingRect.left,
                y: clientY - boundingRect.top,
            };
        } else {
            return null;
        }
    }

    return setCanvasRef;
}
