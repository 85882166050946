import React from "react";
import { useEffect, useState } from "react";
import styles from "../../style";
import axios from 'axios'
import { AiFillDelete } from "react-icons/ai"
import Swal from "sweetalert2";
const TABLE_HEAD = ["ชื่อ", "นามสกุล", "อีเมล", "โรงงาน", ""]

const PermissionManage = () => {

    const [emp, setEmp] = useState([])
    const [empDelete, setEmpDelete] = useState('')
    const [addAdmin, setAddAdmin] = useState(false)
    const [deletePopup, setDeletePopup] = useState(false)
    const [eid, setEid] = useState('')
    const [loading, setLoading] = useState(true)
    
    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setEid((values) => ({ ...values, [name]: value }));
    };

    const handleSubmit = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_API_BASE_URL}/roles/create-role`, { eid });
            // alert("บันทึกข้อมูลสำเร็จ!");
            // window.location.reload();
            Swal.fire({
                icon: 'success',
                title: 'บันทึกข้อมูลสำเร็จ!',
                confirmButtonText: 'ตกลง',
                confirmButtonColor: '#3085d6',
              }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload();
                }
              }); 
            console.log('Data inserted successfully');
        } catch (error) {
            // alert("บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง");
            Swal.fire({
                icon: 'error',
                title: 'บันทึกข้อมูลไม่สำเร็จ',
                text: 'กรุณาลองใหม่อีกครั้ง',
                confirmButtonText: 'ตกลง',
                confirmButtonColor: '#3085d6',
              }); 
            console.error('Error inserting data:', error);
        }
    };

    const handleDelete = (eid) => {
        setDeletePopup(true)
        const filterResults = emp.filter(item => item.eid.includes(eid));
        setEmpDelete(filterResults)
    }

    const handleDeleteConfirm = async () => {
        try {
            await axios.get(`${process.env.REACT_APP_API_BASE_URL}/employees/delete_emp_admin/${empDelete[0].eid}`)
            // alert("ลบข้อมูลสำเร็จ!");
            // window.location.reload();
            Swal.fire({
                icon: 'success',
                title: 'ลบข้อมูลสำเร็จ!',
                confirmButtonText: 'ตกลง',
                confirmButtonColor: '#3085d6',
              }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload();
                }
              }); 
        } catch (error) {
            // alert("ลบข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง");
            Swal.fire({
                icon: 'error',
                title: 'ลบข้อมูลไม่สำเร็จ',
                text: 'กรุณาลองใหม่อีกครั้ง',
                confirmButtonText: 'ตกลง',
                confirmButtonColor: '#3085d6',
              }); 
            console.error('Error remove data:', error);
        }
    }
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                await fetch(
                    `${process.env.REACT_APP_API_BASE_URL}/employees/get-emp-admin`
                ).then(response => response.json())
                    .then(jsonData => {
                        setEmp(jsonData)
                    })
            } catch (error) {
                console.error("เกิดข้อผิดพลาดในการร้องขอข้อมูล", error);
            } finally {
                setLoading(false)
            }
        };
        if (emp.length < 1)
            fetchData();
    }, [emp])

    return (
        <div>
            <p className={`${styles.pageHeading}`}>สิทธิ์การเข้าถึง</p>
            <div className={`${styles.adminPageBox} mt-12`}>

                <button className={`${styles.adminPageBtn} rounded-md hover:bg-hoverBackground`} onClick={() => setAddAdmin(true)}>เพิ่มสิทธิ์ผู้ใช้</button>

                <div className="px-0">
                    {loading ? (
                        <div role="status" className="flex items-center justify-center h-screen">
                            <svg aria-hidden="true" className="inline w-12 h-12 text-gray-200 animate-spin dark:text-borderLine fill-secondary" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                            </svg>
                            <span className="sr-only">Loading...</span>
                        </div>
                    ) : (
                        <table className="w-full min-w-max table-auto text-left mt-4">
                            <thead>
                                <tr>
                                    {TABLE_HEAD.map((head) => (
                                        <th key={head} className="border-y-2 border-blue-gray-100 bg-blue-gray-50/50 p-4 ">
                                            <div className="font-sarabun font-bold leading-none opacity-70" >
                                                {head}
                                            </div>
                                        </th>
                                    ))}
                                </tr>
                            </thead>

                            <tbody>
                                {emp.map(
                                    (item, index) => {
                                        const classes = "p-4 border-b border-blue-gray-50";

                                        return (

                                            <tr key={item.eid} className="font-sarabun">

                                                <td className={classes}>
                                                    <div>
                                                        {item?.name}
                                                    </div>
                                                </td>

                                                <td className={classes}>
                                                    <div>
                                                        {item?.lastname}
                                                    </div>
                                                </td>

                                                <td className={classes}>
                                                    <div>
                                                        {item?.email}
                                                    </div>
                                                </td>

                                                <td className={classes}>
                                                    <div>
                                                        {item?.workArea}
                                                    </div>
                                                </td>

                                                <td className={classes}>
                                                    <div className={`duration-500 text-red-600 cursor-pointer`} onClick={() => handleDelete(item.eid)}>{React.createElement(AiFillDelete, { size: '23' })}</div>
                                                </td>
                                            </tr>
                                        );
                                    },
                                )}
                            </tbody>
                        </table>
                    )}
                </div>
                <footer className="flex items-center justify-between p-4 font-sarabun text-slate-500">
                    <div >
                        หน้าที่ 1 จาก 1
                    </div>
                    <div className="flex gap-2">
                        <button className={`${styles.permissionPageBtn} rounded-md hover:bg-hoverBackground duration-500`}>
                            ย้อนกลับ
                        </button>&nbsp;&nbsp;
                        <button className={`${styles.permissionPageBtn} rounded-md hover:bg-hoverBackground duration-500`}>
                            ถัดไป
                        </button>
                    </div>
                </footer>
            </div>
            {addAdmin && (
                <div id='container' className='fixed z-20 inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center'>
                    <div className='bg-white py-5 sm:px-12 px-8 rounded-xl'>
                        <h3 className="font-bold font-sarabun text-2xl mb-6 flex justify-center">เพิ่มพนักงาน</h3>

                        <div className="mb-12 font-sarabun">
                            <label className="label">
                                <span className="label-text">รหัสพนักงาน</span>
                            </label>
                            <input type="text" name="eid" value={eid.eid || ''} placeholder="กรอกข้อมูล" className="input input-bordered w-full " required onChange={handleChange} />
                        </div>

                        <div className='flex justify-end'>
                            <button className={`${styles.confirmBtn}`} onClick={() => { setEid(''); setAddAdmin(false); handleSubmit(); }}>บันทึก</button>
                            <button className={`${styles.cancelBtn}`} onClick={() => { setEid(''); setAddAdmin(false); }}>ยกเลิก</button>
                        </div>
                    </div>
                </div>
            )}
            {deletePopup && (
                <div id='container' className='fixed z-20 inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center'>
                    <div className='bg-white py-5 sm:px-12 px-8 rounded-xl'>
                        <h3 className="font-bold font-sarabun text-2xl mb-6 flex justify-center">ยืนยันการลบพนักงานท่านนี้หรือไม่</h3>

                        <div className="mb-12 font-sarabun">
                            <p className={`${styles.cardTextBody}`}>รหัสพนักงาน: <label className="font-bold">{empDelete[0].eid}</label></p>
                            <p className={`${styles.cardTextBody}`}>ชื่อ-นามสกุล: <label className="font-bold">{empDelete[0].name} {empDelete[0].lastname}</label></p>
                            <p className={`${styles.cardTextBody}`}>อีเมล: <label className="font-bold">{empDelete[0]?.email}</label></p>
                            <p className={`${styles.cardTextBody}`}>โรงงาน: <label className="font-bold">{empDelete[0].workArea}</label></p>
                        </div>

                        <div className='flex justify-end'>
                            <button className={`${styles.confirmBtn}`} onClick={() => { setDeletePopup(false); handleDeleteConfirm(); }}>ยืนยัน</button>
                            <button className={`${styles.cancelBtn}`} onClick={() => { setDeletePopup(false); }}>ยกเลิก</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default PermissionManage