import React, { useState, useMemo, useEffect } from "react";
import axios from "axios";
import QRCode from "qrcode.react";
import styles from "../../style";
import { SearchBar, DateFormat } from "../../components";
import { STATUS, FILTER_ALL_DATA } from "../../utils";
import { getToken, search, getColorStatusParcel } from "../../services";
import { useMsal } from "../../msalWrapper";
import Swal from "sweetalert2";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";

const newSatus = STATUS.filter(item => ["ST02", "ST03", "ST04"].includes(item.id));

function Table({
  item,
  setSelectStatus,
  setTrackNumRow,
  handleSubmit,
  value,
  onCheck,
}) {
  const classes = "p-4 border-b border-blue-gray-50";
  const classStatus = getColorStatusParcel(item);

  const [isPopupVisible, setPopupVisibility] = useState(false);

  const handlePopupToggle = () => {
    setSelectedOption(item.status);
    setPopupVisibility(false);
    setTrackNumRow([]);
  };

  const handleSaveToggle = () => {
    handleSubmit();
    setPopupVisibility(false);
    setTrackNumRow([]);
  };

  const [selectedOption, setSelectedOption] = useState(item.status);

  useEffect(() => {
    setSelectedOption(item.status);
  }, [item]);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setTrackNumRow([item.tracknum]);
    setPopupVisibility(true);
    if (event.target.value === newSatus["0"].status["th"])
      setSelectStatus(newSatus["0"].id);
    if (event.target.value === newSatus["1"].status["th"])
      setSelectStatus(newSatus["1"].id);
    if (event.target.value === newSatus["2"].status["th"])
      setSelectStatus(newSatus["2"].id);
  };

  const [openPreview, setOpenPreview] = useState("");

  const handleClickOpenPreview = () => {
    setOpenPreview(true);
  };
  const handleClosePreview = () => {
    setOpenPreview(false);
  };

  return (
    <>
      <tr key={item.tracknum} className="font-sarabun border-t">
        <td className={classes}>
          <input
            type="checkbox"
            checked={selectedOption !== "รับพัสดุสำเร็จ" && value}
            className="cursor-pointer"
            onChange={onCheck}
            disabled={selectedOption === "รับพัสดุสำเร็จ"}
          />
        </td>

        <td className={classes}>{item?.senderFullname}</td>

        <td className={classes}>{item?.receiverFullname}</td>

        <td className={classes}>{item?.tracknum}</td>

        <td className={classes}>
          <select
            value={selectedOption}
            onChange={handleOptionChange}
            className={classStatus}
          >
            {newSatus.map((stus) => (
              <option
                key={stus.id}
                value={stus.status["th"]}
                className={stus.style}
                disabled={
                  selectedOption === "รับพัสดุสำเร็จ" &&
                  stus.status["th"] !== "พัสดุถูกตีกลับ"
                }
                style={{
                  color:
                    selectedOption === "รับพัสดุสำเร็จ" &&
                    stus.status["th"] !== "พัสดุถูกตีกลับ"
                      ? "gray"
                      : "",
                }}
              >
                {stus.status["th"]}
              </option>
            ))}
          </select>
        </td>

        <td className={classes}>
          {item?.dateCurrent.split("T")[0]} {item?.dateCurrent.split("T")[1]}
        </td>

        <td className={classes}>{item?.workAreaTo}</td>

        <td className={classes}>{item?.type}</td>

        <td className={classes}>{item?.receivedType}</td>

        <td className={classes}>
          {item?.receivedName}{" "}
          <Button
            size="small"
            disabled={!item?.receivedName}
            variant="contained"
            sx={{ marginLeft: 1, padding: 0 }}
            onClick={handleClickOpenPreview}
          >
            <VisibilityOutlinedIcon />
          </Button>
        </td>
      </tr>
      <Dialog
        open={openPreview}
        onClose={handleClosePreview}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleClosePreview}
            sx={{
              position: "absolute",
              right: 1,
              top: 5,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogTitle
            align="center"
            id="responsive-dialog-title"
            sx={{ color: "primary.main", padding: 1 , fontFamily: "Sarabun",}}
          >
            ลายเซ็น
          </DialogTitle>
          <div>
            <img
              alt="ลายเซ็น"
              src={item?.signature || ""}
              width={250}
              height="100%"
            />
          </div>
        </DialogContent>
      </Dialog>
      {isPopupVisible && (
        <div
          id="container"
          className="fixed z-20 inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center"
        >
          <div className="bg-white py-5 sm:px-12 px-8 rounded-xl">
            <h3 className="font-bold font-sarabun text-2xl mb-6 flex justify-center">
              ยืนยันการเปลี่ยนสถานะ
            </h3>
            <div className="flex">
              <div className="mb-3 flex-start border-2 rounded-md p-5 mr-5">
                <h1 className={`${styles.popupTextHead}`}>ข้อมูลผู้ส่งพัสดุ</h1>
                <p className={`${styles.popupTextBody}`}>
                  หมายเลขติดตามพัสดุ:{" "}
                  <label className="font-bold">{item?.tracknum}</label>
                </p>
                <p className={`${styles.popupTextBody}`}>
                  ชื่อ-นามสกุล:{" "}
                  <label className="font-bold">{item?.senderFullname}</label>
                </p>
                <p className={`${styles.popupTextBody}`}>
                  ประเภท: <label className="font-bold">{item?.type}</label>
                </p>
              </div>

              <div className="mb-3 flex-end border-2 rounded-md p-5">
                <h1 className={`${styles.popupTextHead}`}>ข้อมูลผู้รับพัสดุ</h1>
                <p className={`${styles.popupTextBody}`}>
                  ชื่อ-นามสกุล:{" "}
                  <label className="font-bold">{item?.receiverFullname}</label>
                </p>
                <p className={`${styles.popupTextBody}`}>
                  รหัสพนักงาน:{" "}
                  <label className="font-bold">{item?.receiverEid}</label>
                </p>
                <p className={`${styles.popupTextBody}`}>
                  อีเมล:{" "}
                  <label className="font-bold">{item?.receiverEmail}</label>
                </p>
                <p className={`${styles.popupTextBody}`}>
                  สังกัดหน่วยงาน:{" "}
                  <label className="font-bold">{item?.receiverSection}</label>
                </p>
                <p className={`${styles.popupTextBody}`}>
                  ส่วน:{" "}
                  <label className="font-bold">
                    {item?.receiverDepartment}
                  </label>
                </p>
                <p className={`${styles.popupTextBody}`}>
                  ฝ่าย:{" "}
                  <label className="font-bold">{item?.receiverDivision}</label>
                </p>
              </div>
            </div>
            <div className="mb-3">
              <h1 className={`${styles.popupTextHead}`}>สถานะพัสดุ</h1>
              <DateFormat item={item} />
            </div>
            <div>
              <p className={`${styles.popupTextBody} font-bold`}>
                เปลี่ยนสถานะจาก{" "}
                <label className={classStatus}>{item.status}</label> เป็น{" "}
                <label
                  className={`${
                    selectedOption === "กำลังขนส่งพัสดุ" && "text-orange-500"
                  } ${
                    selectedOption === "ขนส่งพัสดุถึงปลายทาง" && "text-blue-600"
                  } ${
                    selectedOption === "รับพัสดุสำเร็จ" && "text-green-600"
                  } ${selectedOption === "พัสดุถูกตีกลับ" && "text-red-500"}`}
                >
                  {selectedOption}
                </label>
              </p>
            </div>
            <div className="flex justify-end">
              <button
                className={`${styles.confirmBtn}`}
                onClick={handleSaveToggle}
              >
                ยืนยัน
              </button>
              <button
                className={`${styles.cancelBtn}`}
                onClick={handlePopupToggle}
              >
                ยกเลิก
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const TABLE_HEAD = [
  "",
  "ชื่อผู้ส่ง",
  "ชื่อผู้รับ",
  "หมายเลขติดตามพัสดุ",
  "สถานะ",
  "วันที่",
  "โรงงานปลายทาง",
  "ประเภท",
  "ผู้รับ",
  "ลงชื่อ",
];
const headTable = [
  "ลำดับ",
  "หมายเลขติดตามพัสดุ",
  "ชื่อผู้รับ",
  "ชื่อผู้ส่ง",
  "สถานะ",
  "ประเภท",
  "โรงงานปลายทาง",
  "วันที่",
];

const ShowParcelsOut = () => {
  const [parcelData, setParcelData] = useState([]);
  const { instance } = useMsal();
  const [loading, setLoading] = useState(true);

  const adminRole = JSON.parse(localStorage.getItem('adminRole'));
  let checkarea;
  if (adminRole[0].workArea === "สำนักงานใหญ่-นวนคร") {
      const itemsToDelete = ['อมตะซิตี้', 'โรงงานอมตะซิตี้', 'สำนักงานใหญ่-SKL', 'อื่นๆ'];
      checkarea = FILTER_ALL_DATA.checkBox.filter(item => !itemsToDelete.includes(item));
  } else if (adminRole[0].workArea === "โรงงานอมตะซิตี้") {
      const itemsToDelete = ['สำนักงานใหญ่-นวนคร', 'สำนักงานใหญ่-SKL' , 'อื่นๆ'];
      checkarea = FILTER_ALL_DATA.checkBox.filter(item => !itemsToDelete.includes(item));
  } else if (adminRole[0].workArea === "สำนักงานใหญ่-SKL"){
      const itemsToDelete = ['สำนักงานใหญ่-นวนคร', 'อมตะซิตี้', 'โรงงานอมตะซิตี้', 'อื่นๆ'];
      checkarea = FILTER_ALL_DATA.checkBox.filter(item => !itemsToDelete.includes(item));
  }
  const [filterSearch, setFilterSearch] = useState("");
  const [filterCheckbox, setFilterCheckbox] = useState(
    checkarea
  );
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(FILTER_ALL_DATA.endDate);

  useEffect(() => {
    if (parcelData.length < 1) {
      const token = getToken();
      const headers = {
        "Content-Type": "application/json",
        id_token: token.idToken,
        access_token: token.accessToken,
      };

      const fetchData = async () => {
        try {
          await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/parcels/get-parcel-admin-out`,
            {
              method: "GET",
              headers: headers,
            }
          )
            .then((response) => response.json())
            .then((jsonData) => {
              console.log(jsonData);
              setParcelData(jsonData);
            });
        } catch (error) {
          instance.logoutRedirect();
          console.error("เกิดข้อผิดพลาดในการร้องขอข้อมูล", error);
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    }
  }, [parcelData, instance]);

  const handleSubmit = async (name) => {
    try {
      const requestData = {
        status: selectStatus,
        tracknumberList: trackNumRow,
      };
      if (requestData.status === "ST03") {
        openPopup(trackNumRow, name);
      } else {
        await axios.patch(
          `${process.env.REACT_APP_API_BASE_URL}/parcels/update-parcel-status`,
          requestData
        );
        Swal.fire({
          icon: 'success',
          title: 'อัพเดตสถานะพัสดุสำเร็จ!',
          confirmButtonText: 'ตกลง',
          confirmButtonColor: '#3085d6',
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      } 
    } catch (error) {
      // alert("บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง");
      Swal.fire({
        icon: 'error',
        title: 'บันทึกข้อมูลไม่สำเร็จ',
        text: 'กรุณาลองใหม่อีกครั้ง',
        confirmButtonText: 'ตกลง',
        confirmButtonColor: '#3085d6',
      }); 
      console.error("Error inserting data:", error);
    }
  };

  const defaultCheckeds = useMemo(() => {
    let defaultCheckeds = {};
    for (const item of parcelData) {
      defaultCheckeds[item.tracknum] = {
        id: item.tracknum,
        checked: false,
      };
    }
    return defaultCheckeds;
  }, [parcelData]);

  useEffect(() => {
    setCheckValues(defaultCheckeds);
  }, [defaultCheckeds]);

  const [selectedOption, setSelectedOption] = useState();

  const [parcelList, setParcelList] = useState([]);
  const [trackNumRow, setTrackNumRow] = useState([]);
  const [selectStatus, setSelectStatus] = useState();
  const [checkAll, setcheckAll] = useState(false);
  const [checkValues, setCheckValues] = useState({});
  const [popupConfirm, setPopupConfirm] = useState(false);

  const handlePopupToggle = () => {
    for (const parcel of parcelData) {
      if (checkValues[parcel?.tracknum]?.checked) {
        setParcelList((prevParcelList) => [...prevParcelList, parcel]);
        setTrackNumRow((prevTrackNumRow) => [
          ...prevTrackNumRow,
          parcel.tracknum,
        ]);
      }
    }

    if (selectStatus === "ST01") {
      setSelectedOption("กำลังขนส่งพัสดุ");
    } else if (selectStatus === "ST02") {
      setSelectedOption("ขนส่งพัสดุถึงปลายทาง");
    } else if (selectStatus === "ST03") {
      setSelectedOption("รับพัสดุสำเร็จ");
    } else if (selectStatus === "ST04") {
      setSelectedOption("พัสดุถูกตีกลับ");
    } else if (selectStatus === "ST05") {
      setSelectedOption("พัสดุเข้าสู่ระบบ");
    }
  };

  const handlePopupToggleSave = () => {
    const receiverNamesList = parcelList.map(row => row.receiverFullname);
    handleSubmit(receiverNamesList[0]);
    setParcelList([]);
    setTrackNumRow([]);
  };

  const handlePopupToggleClose = () => {
    setParcelList([]);
    setTrackNumRow([]);
  };

  const onSelectCheckbox = (trackNumId) => (event) => {
    setCheckValues((prevCheckeds) => {
      return {
        ...prevCheckeds,
        [trackNumId]: {
          id: trackNumId,
          checked:
            prevCheckeds[trackNumId]?.id === trackNumId &&
            !prevCheckeds[trackNumId]?.checked,
        },
      };
    });
  };

  const onSelectAllCheckbox = () => {
    if (!checkAll) {
      let defaultCheckeds = {};
      for (const item of search(
        parcelData,
        FILTER_ALL_DATA.columnParcel,
        filterSearch,
        filterCheckbox,
        startDate,
        endDate
      )) {
        defaultCheckeds[item.tracknum] = {
          id: item.tracknum,
          checked: true,
        };
      }
      setcheckAll(true);
      setCheckValues(defaultCheckeds);
    } else {
      let defaultCheckeds = {};
      for (const item of parcelData) {
        defaultCheckeds[item.tracknum] = {
          id: item.tracknum,
          checked: false,
        };
      }
      setcheckAll(false);
      setCheckValues(defaultCheckeds);
    }
  };

  const [popupUrl, setPopupUrl] = useState("");

  function openPopup(data, name) {
    // const temp = `https://wonderful-tree-0778b6900.4.azurestaticapps.net/employee/popup_confirm?data=` + encodeURIComponent(data); // URL ที่คุณต้องการให้ popup ไปยัง
    const temp =
      `${process.env.REACT_APP_HOST_POPUP}/employee/popup_confirm?data=` +
      encodeURIComponent(data)+`&name=`+encodeURIComponent(name); // URL ที่คุณต้องการให้ popup ไปยัง
    setPopupUrl(temp);
    setPopupConfirm(true);
  }
  const [PAGE_SIZE, setPageSize] = useState(10);

  const handleChangePageSize = (event) => {
    const newSize = parseInt(event.target.value);
    setPageSize(newSize);
  };

  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const filteredData = search(
    parcelData,
    FILTER_ALL_DATA.columnParcel,
    filterSearch,
    filterCheckbox,
    startDate,
    endDate
  );

  const startIndex = (currentPage - 1) * PAGE_SIZE;
  const endIndex = Math.min(startIndex + PAGE_SIZE, filteredData.length);
  const paginatedData = filteredData.slice(startIndex, endIndex);

  const checkedParcels = Object.values(checkValues).filter(parcel => parcel.checked === true);

  return (
    <div>
      <p className={`${styles.pageHeading} text-orangeSKC`}>
        รายการพัสดุรับจากภายนอกที่อยู่ระหว่างดำเนินการ
      </p>
      <div className={`${styles.adminPageBox} mt-12`}>
        <div className="flex">
          <select
            className={`${checkedParcels.length === 0 ? `${styles.adminPageBtn} bg-gray-400 cursor-not-allowed rounded-md font-sarabun text-white` : `${styles.adminPageBtn} rounded-md font-sarabun`}`}
            onChange={(e) => setSelectStatus(e.target.value)}
            disabled={checkedParcels.length === 0}
          >
            <option value="" disabled>
              กรุณาเลือกสถานะ
            </option>
            {newSatus.map((stus) => (
              <option value={stus.id} className={stus.style}>
                {stus.status["th"]}
              </option>
            ))}
          </select>
          <button
            className={`${checkedParcels.length === 0 ? `${styles.adminPageBtn} bg-gray-400 cursor-not-allowed rounded-md w-48 ml-3 text-white` : `${styles.adminPageBtn} rounded-md w-48 ml-3`}`}
            onClick={handlePopupToggle}
            disabled={checkedParcels.length === 0}
          >
            บันทึกสถานะ
          </button>

          <SearchBar
            setFilterSearch={setFilterSearch}
            filterCheckbox={filterCheckbox}
            setFilterCheckbox={setFilterCheckbox}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            showStatusFilters={"ShowParcelsOut"}
          />
        </div>
        <div className="px-0 ">
          {loading ? (
            <div
              role="status"
              className="flex items-center justify-center h-screen"
            >
              <svg
                aria-hidden="true"
                className="inline w-12 h-12 text-gray-200 animate-spin dark:text-borderLine fill-secondary"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <div className="overflow-x-auto">
              <table className="w-full min-w-max table-auto text-left mt-4">
                <thead>
                  <tr>
                    {TABLE_HEAD.map((head, index) => (
                      <th
                        key={index}
                        className="border-y-2 border-blue-gray-100 bg-blue-gray-50/50 p-4 "
                      >
                        <div className="font-sarabun font-bold leading-none opacity-70">
                          {index !== 0 ? (
                            head
                          ) : (
                            <input
                              type="checkbox"
                              className="cursor-pointer"
                              onClick={onSelectAllCheckbox}
                            ></input>
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody>
                  {paginatedData.map((item, index) =>
                    item && item?.tracknum ? (
                      <Table
                        key={item.tracknum}
                        item={item}
                        index={index}
                        trackNumRow={trackNumRow}
                        setTrackNumRow={setTrackNumRow}
                        setSelectStatus={setSelectStatus}
                        handleSubmit={() => handleSubmit(item.receiverFullname)}
                        value={checkValues?.[item.tracknum]?.checked ?? false}
                        onCheck={onSelectCheckbox(item.tracknum)}
                      />
                    ) : null
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>

        <div className="flex items-center justify-between p-4 font-sarabun text-slate-500">
          <div>
            หน้าที่ {currentPage} จาก{" "}
            {Math.ceil(filteredData.length / PAGE_SIZE)}
          </div>
          <div>
            <select
              id="pageSizeSelect"
              value={PAGE_SIZE}
              onChange={handleChangePageSize}
              className={`${styles.permissionPageBtn} rounded-md hover:bg-hoverBackground duration-500`}
              style={{
                display: filteredData.length === 0 ? "none" : "inline-block",
              }}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
            </select>
            &nbsp;&nbsp;
            <button
              className={`${styles.permissionPageBtn} rounded-md hover:bg-hoverBackground duration-500`}
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={
                currentPage === 1 ||
                Math.ceil(filteredData.length / PAGE_SIZE) === 0
              }
              style={{
                display: filteredData.length === 0 ? "none" : "inline-block",
              }}
            >
              ย้อนกลับ
            </button>
            &nbsp;&nbsp;
            <button
              className={`${styles.permissionPageBtn} rounded-md hover:bg-hoverBackground duration-500`}
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={
                currentPage === Math.ceil(filteredData.length / PAGE_SIZE) ||
                Math.ceil(filteredData.length / PAGE_SIZE) === 0
              }
              style={{
                display: filteredData.length === 0 ? "none" : "inline-block",
              }}
            >
              ถัดไป
            </button>
          </div>
        </div>
      </div>
      {parcelList.length > 0 && (
        <div
          id="container"
          className="fixed z-20 inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center"
        >
          <div className="bg-white py-5 sm:px-12 px-8 rounded-xl">
            <h3 className="font-bold font-sarabun text-2xl mb-6 flex justify-center">
              ยืนยันการเปลี่ยนสถานะ
            </h3>
            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
              <table className="table">
                <thead style={{ maxHeight: "200px", overflowY: "auto" }}>
                  <tr>
                    {headTable.map((value, index) => (
                      <th className="font-sarabun" key={index}>
                        {value}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody style={{ maxHeight: "200px", overflowY: "auto" }}>
                  {parcelList.map((row, index) => (
                    <tr className="font-sarabun" key={index}>
                      <td>{index + 1}</td>
                      <td>{row.tracknum}</td>
                      <td>{row.senderFullname}</td>
                      <td>{row.receiverFullname}</td>
                      <td>{row.status}</td>
                      <td>{row.type}</td>
                      <td>{row.workAreaTo}</td>
                      <td>{row.dateCurrent.split("T")[0]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div>
              <p className={`${styles.popupTextBody} mt-3 font-bold`}>
                เปลี่ยนสถานะพัสดุทั้งหมด {parcelList.length} รายการเป็น{" "}
                <label
                  className={`${
                    selectedOption === "กำลังขนส่งพัสดุ" && "text-orange-500"
                  } ${
                    selectedOption === "ขนส่งพัสดุถึงปลายทาง" && "text-blue-600"
                  } ${selectedOption === "รับพัสดุสำเร็จ" && "text-green-600"}`}
                >
                  {selectedOption}
                </label>
              </p>
            </div>

            <div className="flex justify-end">
              <button
                className={`${styles.confirmBtn} `}
                onClick={handlePopupToggleSave}
              >
                ยืนยัน
              </button>
              <button
                className={`${styles.cancelBtn} `}
                onClick={handlePopupToggleClose}
              >
                ยกเลิก
              </button>
            </div>
          </div>
        </div>
      )}
      {popupConfirm > 0 && (
        <div
          id="container"
          className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center"
        >
          <div className="bg-white py-5 sm:px-12 px-8 rounded-xl">
            <div>
              <h
                className="font-sarabun text-xl underline text-blue-500 cursor-pointer"
                onClick={() =>
                  window.open(popupUrl, "_blank", "width=600,height=400")
                }
              >
                กดหรือแสกนที่คิวอาร์โค้ดเพื่อเซ็นรับพัสดุ
              </h>
              {/* <a href={popupUrl} target="_blank" rel="noopener noreferrer" className='font-sarabun text-xl underline text-blue-500'>กดหรือแสกนที่คิวอาร์โค้ดเพื่อเซ็นรับพัสดุ</a> */}
            </div>
            <div className="my-5 flex justify-center">
              <QRCode value={popupUrl} />
            </div>
            <div className="flex justify-end">
              <button
                className={`${styles.confirmBtn}`}
                onClick={() => window.location.reload()}
              >
                เสร็จสิ้น
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShowParcelsOut;
