import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from "../../style";
import { SearchBar, Cards, Loading } from "../../components";
import { getToken, search } from "../../services";
import { useMsal } from "../../msalWrapper";
import { FILTER_ALL_DATA } from "../../utils";
import { GiCardboardBoxClosed } from "react-icons/gi";

const ShowParcels = () => {
  const [parcelData, setParcelData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageTypeBtn, setPageTypeBtn] = useState(true);
  const { instance } = useMsal();

  const [filterSearch, setFilterSearch] = useState("");
  const [filterCheckbox, setFilterCheckbox] = useState(
    FILTER_ALL_DATA.checkBox
  );
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(FILTER_ALL_DATA.endDate);

  useEffect(() => {
    const token = getToken();
    const headers = {
      "Content-Type": "application/json",
      id_token: token.idToken,
      access_token: token.accessToken,
    };

    const fetchData = async () => {
      try {
        const resNormalParcel = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/parcels/get-parcel-user`,
          { headers: headers }
        );
        const resCompanyParcel = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/parcels/get-parcel-user-company`,
          { headers: headers }
        );
        setParcelData([...resNormalParcel.data, ...resCompanyParcel.data]);
      } catch (error) {
        console.error("Error can't get data: ", error);
        instance.logoutRedirect();
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [instance]);

  parcelData.sort((a, b) => {
    const dateA = new Date(b.dateCurrent);
    const dateB = new Date(a.dateCurrent);
    return dateA - dateB;
  });

  const [PAGE_SIZE, setPageSize] = useState(10);

  const handleChangePageSize = (event) => {
    const newSize = parseInt(event.target.value);
    setPageSize(newSize);
  };

  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const datadict = parcelData.slice(1);

  const filteredData = search(
    datadict,
    FILTER_ALL_DATA.columnParcel,
    filterSearch,
    filterCheckbox,
    startDate,
    endDate
  );

  const startIndexNonPageType = (currentPage - 1) * PAGE_SIZE;
  const endIndexNonPageType = Math.min(
    startIndexNonPageType + PAGE_SIZE,
    filteredData.filter((item) => !pageTypeBtn && item.senderEid).length
  );
  const paginatedDataNonPageType = filteredData
    .filter((item) => !pageTypeBtn && item.senderEid === parcelData[0])
    .slice(startIndexNonPageType, endIndexNonPageType);

  // Calculate the paginated subset of data based on the filtered results and pagination count for pageTypeBtn
  const startIndexPageType = (currentPage - 1) * PAGE_SIZE;
  const endIndexPageType = Math.min(
    startIndexPageType + PAGE_SIZE,
    filteredData.filter((item) => pageTypeBtn && item.receiverEid).length
  );
  const paginatedDataPageType = filteredData
    .filter((item) => pageTypeBtn && item.receiverEid === parcelData[0])
    .slice(startIndexPageType, endIndexPageType);
  // console.log(paginatedDataNonPageType)
  // console.log(paginatedDataPageType)

  // Concatenate the paginated subsets of data
  const paginatedData = [...paginatedDataNonPageType, ...paginatedDataPageType];
  return (
    <div>
      <p className={`${styles.pageHeading}`}>รายการพัสดุของท่าน</p>
      <div className="grid grid-cols-1 sm:grid-cols-2 w-full">
        <div className="flex justify-start sm:mb-0 mb-2">
          <button
            className={`${
              pageTypeBtn
                ? `${styles.defaultBtnFocus} border-secondary bg-secondary`
                : `${styles.defaultBtn} border-orangeSKC text-slate-400`
            } rounded-l-md`}
            onClick={() => setPageTypeBtn(true)}
          >
            รายการพัสดุที่ได้รับ
          </button>
          <button
            className={`${
              !pageTypeBtn
                ? `${styles.defaultBtnFocus} border-orangeSKC bg-orangeSKC`
                : `${styles.defaultBtn} border-secondary text-slate-400`
            } rounded-r-md`}
            onClick={() => setPageTypeBtn(false)}
          >
            รายการพัสดุที่จัดส่ง
          </button>
        </div>
        <div className="w-full">
          <SearchBar
            setFilterSearch={setFilterSearch}
            filterCheckbox={filterCheckbox}
            setFilterCheckbox={setFilterCheckbox}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
        </div>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className={`${styles.cardContainer}`}>
            {/* GRID */}
            <div className={`${styles.cardGrid}`}>
              {/* CARD */}
              {paginatedData.map((item, index) =>
                (!pageTypeBtn && item.senderEid === parcelData[0]) ||
                (pageTypeBtn && item.receiverEid === parcelData[0]) ? (
                  <Cards
                    key={index}
                    item={item}
                    index={index}
                    pageTypeBtn={pageTypeBtn}
                  />
                ) : null
              )}
            </div>
          </div>
        </>
      )}
      <footer className="flex items-center justify-between p-4 font-sarabun w-full">
        {paginatedData.filter(
          (item) =>
            (!pageTypeBtn && item.senderEid === parcelData[0]) ||
            (pageTypeBtn && item.receiverEid === parcelData[0])
        ).length === 0 ? (
          // <div>ไม่มีรายการพัสดุ</div>
          <div className="flex justify-center items-center flex-col overflow-auto w-full">
            <GiCardboardBoxClosed style={{ fontSize: "300px" }} />
            <p style={{ fontSize: "30px" }}>
              ไม่มีรายการพัสดุ
            </p>
          </div>
        ) : (
          <div>
            หน้าที่ {currentPage} จาก{" "}
            {Math.ceil(
              filteredData.filter(
                (item) =>
                  (!pageTypeBtn && item.senderEid === parcelData[0]) ||
                  (pageTypeBtn && item.receiverEid === parcelData[0])
              ).length / PAGE_SIZE
            )}
            {pageTypeBtn
              ? " (รายการพัสดุที่ได้รับ)"
              : " (รายการพัสดุที่จัดส่ง)"}
          </div>
        )}
        <div>
          <select
            id="pageSizeSelect"
            value={PAGE_SIZE}
            onChange={handleChangePageSize}
            className={`${styles.permissionPageBtn} rounded-md hover:bg-hoverBackground duration-500`}
            style={{
              display:
                filteredData.filter(
                  (item) =>
                    (!pageTypeBtn && item.senderEid === parcelData[0]) ||
                    (pageTypeBtn && item.receiverEid === parcelData[0])
                ).length === 0
                  ? "none"
                  : "inline-block",
            }}
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={15}>15</option>
          </select>
          &nbsp;&nbsp;
          <button
            className={`${styles.permissionPageBtn} rounded-md hover:bg-hoverBackground duration-500`}
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={
              currentPage === 1 ||
              Math.ceil(
                filteredData.filter(
                  (item) =>
                    (!pageTypeBtn && item.senderEid === parcelData[0]) ||
                    (pageTypeBtn && item.receiverEid === parcelData[0])
                ).length / PAGE_SIZE
              ) === 0
            }
            style={{
              display:
                filteredData.filter(
                  (item) =>
                    (!pageTypeBtn && item.senderEid === parcelData[0]) ||
                    (pageTypeBtn && item.receiverEid === parcelData[0])
                ).length === 0
                  ? "none"
                  : "inline-block",
            }}
          >
            ย้อนกลับ
          </button>
          &nbsp;&nbsp;
          <button
            className={`${styles.permissionPageBtn} rounded-md hover:bg-hoverBackground duration-500`}
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={
              currentPage ===
                Math.ceil(
                  filteredData.filter(
                    (item) =>
                      (!pageTypeBtn && item.senderEid === parcelData[0]) ||
                      (pageTypeBtn && item.receiverEid === parcelData[0])
                  ).length / PAGE_SIZE
                ) ||
              Math.ceil(
                filteredData.filter(
                  (item) =>
                    (!pageTypeBtn && item.senderEid === parcelData[0]) ||
                    (pageTypeBtn && item.receiverEid === parcelData[0])
                ).length / PAGE_SIZE
              ) === 0
            }
            style={{
              display:
                filteredData.filter(
                  (item) =>
                    (!pageTypeBtn && item.senderEid === parcelData[0]) ||
                    (pageTypeBtn && item.receiverEid === parcelData[0])
                ).length === 0
                  ? "none"
                  : "inline-block",
            }}
          >
            ถัดไป
          </button>
        </div>
      </footer>
    </div>
  );
};

export default ShowParcels;
