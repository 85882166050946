import { forwardRef, useImperativeHandle, useRef, useEffect, useState } from "react";
import { useOnDraw } from "../services";

const Canvas = forwardRef(({ parentWidth, parentHeight }, ref) => {
  const canvasRef = useRef(null);
  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const setCanvasRef = useOnDraw(onDraw);

  useImperativeHandle(ref, () => canvasRef.current);

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        // Get the viewport width and height
        const vw = Math.min(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        const vh = Math.min(document.documentElement.clientHeight || 0, window.innerHeight || 0);

        // Calculate dimensions while maintaining aspect ratio
        let width = Math.min(vw * 0.9, 700); // 90% of viewport width, max 700px
        let height = Math.min(vh * 0.4, 500); // 40% of viewport height, max 500px

        // Ensure minimum sizes for usability
        width = Math.max(width, 280); // Minimum width
        height = Math.max(height, 200); // Minimum height

        setDimensions({ width, height });
      }
    };

    // Initial update
    updateDimensions();

    // Add resize listener
    window.addEventListener('resize', updateDimensions);

    // Cleanup
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  function onDraw(ctx, point, prevPoint) {
    drawLine(prevPoint, point, ctx, '#000000', getStrokeWidth());
  }

  function getStrokeWidth() {
    // Adjust stroke width based on screen size
    const width = dimensions.width;
    if (width < 400) return 2;
    if (width < 600) return 3;
    return 4;
  }

  function drawLine(start, end, ctx, color, width) {
    start = start ?? end;
    ctx.beginPath();
    ctx.lineWidth = width;
    ctx.strokeStyle = color;
    ctx.moveTo(start.x, start.y);
    ctx.lineTo(end.x, end.y);
    ctx.stroke();
  }

  return (
    <div ref={containerRef} className="w-full flex justify-center items-center">
      <canvas
        width={dimensions.width}
        height={dimensions.height}
        className="border-2 border-gray-300 rounded-lg touch-none"
        ref={(node) => {
          canvasRef.current = node;
          setCanvasRef(node);
        }}
        style={{
          maxWidth: '100%',
          touchAction: 'none'
        }}
      />
    </div>
  );
});

export default Canvas;