import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { getToken } from '../services';

function DropdownSearch({ searchTerm, handleClick, name }) {

    const { instance } = useMsal();
    const [empData, setEmpData] = useState([])

    useEffect(() => {
        const token = getToken();
        const headers = {
            "Content-Type": "application/json",
            id_token: token.idToken,
            access_token: token.accessToken,
        };

        const fetchData = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_BASE_URL}/employees/get-all-emp`,
                    {
                        method: "GET",
                        headers: headers,
                    }
                );
                if (!response.ok) {
                    instance.logoutRedirect();
                }
                const jsonData = await response.json();
                setEmpData(jsonData);
            } catch (error) {
                console.error("เกิดข้อผิดพลาดในการร้องขอข้อมูล", error);
            }
        };
        if (empData.length < 1) {
            fetchData();
        }
    }, [empData, instance]);

    const filteredEmps = empData.filter((emp) =>
        (emp.nameTH?.toLowerCase()?.includes(searchTerm?.split(' ')[0]?.toLowerCase()) && (searchTerm?.split(' ').length < 2 || emp.lastnameTH?.toLowerCase()?.includes(searchTerm?.split(' ')[1]?.toLowerCase())))
        || emp.eid?.includes(searchTerm)
    );

    return (
        searchTerm.length > 0 && searchTerm !== filteredEmps[0]?.nameTH + " " + filteredEmps[0]?.lastnameTH && filteredEmps.length > 0 && (
            <div className="relative">
                <ul className="absolute z-10 mt-2 py-2 px-4 bg-white border border-gray-300 rounded-md w-full">
                    {filteredEmps.slice(0, 10).map((result, index) => (
                        <li
                            key={index}
                            className="cursor-pointer hover:bg-gray-200 hover:rounded"
                            onClick={() => handleClick(name, result.nameTH + ' ' + result.lastnameTH, result.eid)}
                        >
                            {result.eid} {result.nameTH} {result.lastnameTH}
                        </li>
                    ))}
                </ul>
            </div>
        )
    );
}

export default DropdownSearch;
