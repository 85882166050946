export function search(
  data,
  columnParcel,
  filterSearch,
  filterCheckbox,
  startDate,
  endDate
) {
  const updateFilterCheckbox = () => {
    if (filterCheckbox.includes("อื่นๆ")) {
      const index = filterCheckbox.indexOf(3);
      data.forEach((item) => {
        if (
          item["workAreaTo"] &&
          !filterCheckbox.includes(item["workAreaTo"])
        ) {
          if (
            item["workAreaTo"] !== "สำนักงานใหญ่-นวนคร" &&
            item["workAreaTo"] !== "อมตะซิตี้" &&
            item["workAreaTo"] !== "undefined" &&
            item["workAreaTo"] !== "โรงงานอมตะซิตี้" &&
            item["workAreaTo"] !== "สำนักงานใหญ่-SKL"
          ) {
            if (index !== -1) {
              filterCheckbox.splice(index, 0, item["workAreaTo"]);
            }
            filterCheckbox.push(item["workAreaTo"]);
          }
        }
      });
    } else {
      data.forEach((item) => {
        if (
          item["workAreaTo"] &&
          item["workAreaTo"] !== "สำนักงานใหญ่-นวนคร" &&
          item["workAreaTo"] !== "อมตะซิตี้" &&
          item["workAreaTo"] !== "โรงงานอมตะซิตี้" &&
          item["workAreaTo"] !== "สำนักงานใหญ่-SKL"
        ) {
          // Find the index of the item in filterCheckbox and remove it
          const removeIndex = filterCheckbox.indexOf(item["workAreaTo"]);
          if (removeIndex !== -1) {
            filterCheckbox.splice(removeIndex, 1);
          }
        }
      });
    }
  };
  updateFilterCheckbox();
  console.log(filterCheckbox);
  return data.filter((item) =>
    columnParcel.some(
      (index) =>
        item[index]?.toLowerCase()?.includes(filterSearch?.toLowerCase()) &&
        filterCheckbox.some((filterItem) =>
          item["status"]?.includes(filterItem)
        ) &&
        filterCheckbox.some(
          (filterItem) =>
            (filterItem === "-" &&
              item["workAreaTo"] !== "สำนักงานใหญ่-นวนคร" &&
              item["workAreaTo"] !== "โรงงานอมตะซิตี้" &&
              item["workAreaTo"] !== "สำนักงานใหญ่-SKL") ||
            (filterItem !== "-" && item["workAreaTo"]?.includes(filterItem))
        ) &&
        filterCheckbox.some((filterItem) =>
          item["type"]?.includes(filterItem)
        ) &&
        item["dateCurrent"]?.split("T")[0] >= startDate &&
        item["dateCurrent"]?.split("T")[0] <= endDate
    )
  );
}
