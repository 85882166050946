import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { HiMenuAlt3 } from "react-icons/hi"
import { FaUserCircle } from "react-icons/fa"
import { useMsal } from "../msalWrapper";
import { getToken } from '../services';

const Banner = ({ setOpenMenu, openMenu, adminRole, setAdminRole }) => {

    let menuRef = useRef();
    const [open, setOpen] = useState(false);
    const { instance, accounts } = useMsal(); // Access the MSAL context
    const handleLogout = () => { instance.logoutRedirect(); };

    useEffect(() => {
        let handler = (e) => {
            if (!menuRef.current.contains(e.target)) {
                setOpen(false);
            };
        };
        document.addEventListener("mousedown", handler);

        return () => {
            document.removeEventListener("mousedown", handler);
        }
    });

    const getRole = React.useCallback(async () => {

        const token = getToken();
        const headers = {
            "Content-Type": "application/json",
            id_token: token.idToken,
            access_token: token.accessToken,
        };
        
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/roles/get-role`, {
                headers: headers,
            });
            setAdminRole(response.data)
            localStorage.setItem('adminRole', JSON.stringify(response.data));
            
        } catch (error) {
            if (error.response.status === 403 && window.location.pathname.split('/')[1] === 'admin') {
                window.location.href = '/employee/show_parcels'
            } else if (error.response.status === 401) {
                instance.logoutRedirect();
            } else if (error.response.status === 403) {
                console.log("no admin permission.")
            } else {
                console.error("Error can't get role in database", error);
            }
        }
    }, [instance, setAdminRole,])

    useEffect(() => {
        getRole()
    }, [getRole])

    const jsonEmp = JSON.parse(localStorage.getItem('adminRole') ?? '[]')[0]?.email ?? null;

    return (

        <div className="w-full h-[60px] flex items-center justify-between px-7 border-b bg-slate-50" >

            <HiMenuAlt3 className={`cursor-pointer`} size={25} onClick={() => { setOpenMenu(!openMenu) }} />
            <div className='pl-9'>
                <h1 className={`font-sarabun font-bold text-[20px] `}>ระบบติดตามพัสดุ</h1>
                <p className={`font-sarabun text-[12px] text-secondary`}>สยามคูโบต้าคอร์ปอเรชั่น</p>
            </div>
            <div className={`list-none flex justify-end items-center flex-1`} ref={menuRef}>
                <FaUserCircle size={24} className='text-secondary cursor-pointer' onClick={() => { setOpen(!open) }} />
                <p className='font-sarabun text-[16px] pl-[10px] cursor-pointer ss:flex hidden' onClick={() => { setOpen(!open) }}>{accounts[0]?.name}</p>

                <div className={`dropdown-profile ${open ? 'active' : 'inactive'}`} >
                    <ul> {adminRole && adminRole.length !== 0 && (
                        <li className={`dropdown-profile-items`} >
                            {jsonEmp === "thaipost.admin01@siamkubota.co.th" ? (
                                <a href="/admin/add_parcel_weigh">เข้าสู่ระบบเจ้าหน้าที่</a>
                            ) : (
                                <a href="/admin/show_parcels_in">เข้าสู่ระบบเจ้าหน้าที่</a>
                            )}
                        </li>)}
                        {adminRole && adminRole.length !== 0 && (
                            <li className={`dropdown-profile-items`} >
                                <a href="/employee/show_parcels">เข้าสู่ระบบผู้ใช้</a>
                            </li>)}
                        <li className={`dropdown-profile-items`}>
                            {/* <button className="font-sarabun ml-4" > {"ออกจากระบบ"} </button> */}
                            <button className="font-sarabun ml-4" onClick={handleLogout}>ออกจากระบบ</button>
                            {/* <LogoutButton className="font-sarabun ml-4" /> */}
                        </li>
                    </ul>
                </div>
            </div>

        </div>

    )
}

export default Banner