export function shippedIDTranferTextToID( shippedText ) {
    if (shippedText === "EMS - ในประเทศ") { return "Se1" }
    else if (shippedText === "EMS - นอกประเทศ") { return "Se2" }
    else if (shippedText === "ลงทะเบียน - ในประเทศ") { return "Sr1" }
    else if (shippedText === "ลงทะเบียน - นอกประเทศ") { return "Sr2" }
    else if (shippedText === "พัสดุ - ในประเทศ") { return "Sp1" }
    else if (shippedText === "พัสดุ - นอกประเทศ") { return "Sp2" }
    else if (shippedText === "ธรรมดา - นอกประเทศ") { return "So2" }
    else if (shippedText === "ธรรมดา - ในประเทศ") { return "So1" }
    else { return null}
}

export function shippedIDTranferIDToText( shippedID ) {
    if (shippedID === "Se1") { return "EMS - ในประเทศ" }
    else if (shippedID === "Se2") { return "EMS - นอกประเทศ" }
    else if (shippedID === "Sr1") { return "ลงทะเบียน - ในประเทศ" }
    else if (shippedID === "Sr2") { return "ลงทะเบียน - นอกประเทศ" }
    else if (shippedID === "Sp1") { return "พัสดุ - ในประเทศ" }
    else if (shippedID === "Sp2") { return "พัสดุ - นอกประเทศ" }
    else if (shippedID === "So2") { return "ธรรมดา - นอกประเทศ" }
    else if (shippedID === "So1") { return "ธรรมดา - ในประเทศ" }
    else { return null}
}