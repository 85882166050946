import React, { useEffect } from 'react';
import { useMsal } from '../msalWrapper'; // Import the MSAL context hook

const Login = () => {
  const { instance, accounts } = useMsal(); // Access the MSAL context

  useEffect(() => {
    const handleLogin = async () => {
      try {
        const loginResponse = await instance.loginRedirect();
        console.log(loginResponse)
      } catch (error) {
        console.error(error)
      }
    }

    if (accounts.length <= 0) {
      handleLogin()
    }

  }, [accounts, instance])

  return (
    <>
    </>
  )
};

export default Login;