export function getPrice(weight, shippedId, rates) {
    for (let i = 0; i < rates.length; i++) {
        if (weight <= rates[i].maxRange) {
            if (weight <= 2000) {
                if (shippedId === 'So1' || shippedId === 'So2') {
                    return rates[i].priceLetter
                } else if (shippedId === 'Sp1' || shippedId === 'Sp2') {
                    return rates[i].priceNormal
                } else if (shippedId === 'Sr1' || shippedId === 'Sr2') {
                    return rates[i].priceRegister
                } else if (shippedId === 'Se1' || shippedId === 'Se2') {
                    return rates[i].priceEms
                } else {
                    return 'Error'
                }
            } else {
                if (shippedId === 'So1' || shippedId === 'So2'|| shippedId === 'Sp1' || shippedId === 'Sp2') {
                    return rates[i].priceNormal
                } else if (shippedId === 'Sr1' || shippedId === 'Sr2' || shippedId === 'Se1' || shippedId === 'Se2') {
                    return rates[i].priceEms
                } else {
                    return 'Error'
                }
            }
        }
    }
}