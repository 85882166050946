import React from 'react';
import { MsalProvider, useMsal } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { useLocation } from 'react-router-dom';

// Your MSAL configuration
const msalConfig = {
  auth: {
    authority: process.env.REACT_APP_MSAL_CONFIG_AUTHORITY,
    clientId: process.env.REACT_APP_MSAL_CONFIG_CLIENTID,
    redirectUri: process.env.REACT_APP_MSAL_CONFIG_REDIRECTURI,
  },
  cache: {
    cacheLocation: 'localStorage', // Use sessionStorage for better security
    storeAuthStateInCookie: true, // Set to true if you want to use cookies for auth state
  },
};

// Create the MSAL instance
const msalInstance = new PublicClientApplication(msalConfig);

// Create the MSAL context
export const MsalContext = React.createContext();

export const MsalWrapper = ({ children }) => {
  const location = useLocation();

  const unauthenticatedRoutes = ['/employee/popup_confirm'];
  const isUnauthenticatedRoute = unauthenticatedRoutes.includes(location.pathname);

  return (
    <>
      {isUnauthenticatedRoute ? (
        children
      ) : (
        <MsalProvider instance={msalInstance}>
          {children}
        </MsalProvider>
      )}
    </>
  );
};

export { useMsal };