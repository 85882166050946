import React, { useState, useEffect } from 'react'
import axios from 'axios'
import styles from "../../style"
import { getToken } from '../../services'
import { useMsal } from "../../msalWrapper";
import { shippedIDTranferIDToText } from '../../services';
import Swal from "sweetalert2";

const EditParcel = () => {

    const [parcelCompanyList, setParcelCompanyList] = useState({
        receiverName: '',
        receiverZip: '',
        shippedID: '',
        parcelType: '',
        department: '',
        costCenter: '',
        gl: '',
        order: '',
        trackNum: '',
        id: ''
    });

    const { instance } = useMsal();
    const [loading, setLoading] = useState(true)
    const [popupVisible, setPopupVisibility] = useState(false);

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let filteredText = ''

        if (name === 'receiverZip') {
            filteredText = value.replace(/[^0-9]/g, '');
        } else if (name === 'costCenter') {
            filteredText = value.replace(/[^a-zA-Z0-9]/g, '');
        } else if (name === 'gl') {
            filteredText = value.replace(/[^0-9]/g, '');
        } else if (name === 'order') {
            filteredText = value.replace(/[^a-zA-Z0-9]/g, '');
        } else if (name === 'trackNum') {
            filteredText = value.replace(/[^a-zA-Z0-9]/g, '');
        } else {
            filteredText = value
        }
        setParcelCompanyList((values) => ({ ...values, [name]: filteredText }));
    };

    useEffect(() => {
        const currentUrl = window.location.href;
        const currentUrlObj = new URL(currentUrl);
        const currentParams = new URLSearchParams(currentUrlObj.search);
        const token = getToken();
        const headers = {
            "Content-Type": "application/json",
            id_token: token.idToken,
            access_token: token.accessToken,
        };

        const getParcelData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_BASE_URL}/parcels/get-parcel-by-id?id=` + encodeURIComponent(currentParams.get('id')),
                    { headers: headers }
                );
        
                const parcelData = response.data;
        
                setParcelCompanyList({
                    receiverName: parcelData[0].receiverFullname || '',
                    receiverZip: parcelData[0].receiverZip || '',
                    shippedID: parcelData[0].type || '',
                    parcelType: parcelData[0].kind || '',
                    department: parcelData[0].dapartment || '',
                    costCenter: parcelData[0].costcenter || '',
                    gl: parcelData[0].GL || '',
                    order: parcelData[0].order || '',
                    trackNum: parcelData[0].tracknum || '',
                    id: parcelData[0].pcRunid || '',
                });
        
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        getParcelData();
    }, [instance]);

    const handleEditParcel = () => {
        const token = getToken();
        const headers = {
            "Content-Type": "application/json",
            id_token: token.idToken,
            access_token: token.accessToken,
        };
        try {
             axios.put(`${process.env.REACT_APP_API_BASE_URL}/parcels/edit-parcel-company`,
                { parcelCompanyList: parcelCompanyList }, {
                headers: headers});
            // window.location.reload()
            // alert("บันทึกข้อมูลสำเร็จ!");
            Swal.fire({
                icon: 'success',
                title: 'แก้ไขข้อมูลสำเร็จ!',
                confirmButtonText: 'ตกลง',
                confirmButtonColor: '#3085d6',
              }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload();
                }
              }); 
            console.log('Data inserted successfully');
        } catch (error) {
            // alert("บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง");
            Swal.fire({
                icon: 'error',
                title: 'บันทึกข้อมูลไม่สำเร็จ',
                text: 'กรุณาลองใหม่อีกครั้ง',
                confirmButtonText: 'ตกลง',
                confirmButtonColor: '#3085d6',
              }); 
            console.error('Error inserting data:', error);
        }
    };

    const handleKeyDown = (e) => {
        const allowedKeys = /[a-zA-Z0-9]/;
        console.log(e.key)
        if (!allowedKeys.test(e.key)) {
            console.log(e)
            e.preventDefault();
        }
    };

    return (
        <div>
            <p className={`${styles.pageHeading}`}>รายการส่งพัสดุของท่าน / แก้ไขพัสดุ</p>
            <div className={`${styles.adminPageBox} mt-12 bg-white`}>
                {loading ? (
                    <div role="status" className="flex items-center justify-center h-screen">
                        <svg aria-hidden="true" className="inline w-12 h-12 text-gray-200 animate-spin dark:text-borderLine fill-secondary" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                        </svg>
                        <span className="sr-only">Loading...</span>
                    </div>
                ) : (
                    <form onSubmit={(e) => { e.preventDefault(); setPopupVisibility(!popupVisible) }}>
                        <div className={`${styles.cardContainer}`}>
                            <div className={`${styles.adminGrids} ${styles.adminPageBox}`}>

                                <div className="">
                                    <label className="label">
                                        <span className="label-text">ชื่อ-สกุลผู้รับ</span>
                                    </label>
                                    <input type="text" name="receiverName" value={parcelCompanyList.receiverName || ''} placeholder="กรอกข้อมูล" className="input input-bordered w-full" required onChange={handleChange} />
                                </div >

                                <div className="">
                                    <label className="label">
                                        <span className="label-text">รหัสไปรษณีย์ปลายทาง</span>
                                    </label>
                                    <input type="text" name="receiverZip" value={parcelCompanyList.receiverZip || ''} placeholder="กรอกข้อมูล" className="input input-bordered w-full" required onChange={handleChange} maxLength={5} />
                                </div>

                                <div className="">
                                    <label className="label">
                                        <span className="label-text">ประเภทการจัดส่ง</span>
                                    </label>
                                    <select required name="shippedID" value={parcelCompanyList.shippedID || ''} className="input input-bordered w-full" onChange={handleChange}>
                                        <option value="" disabled>กรุณาเลือก</option>
                                        <option value="Se1">EMS - ในประเทศ</option>
                                        <option value="Se2">EMS - นอกประเทศ</option>
                                        <option value="Sr1">ลงทะเบียน - ในประเทศ</option>
                                        <option value="Sr2">ลงทะเบียน - นอกประเทศ</option>
                                        <option value="Sp1">พัสดุ - ในประเทศ</option>
                                        <option value="Sp2">พัสดุ - นอกประเทศ</option>
                                        <option value="So1">ธรรมดา - ในประเทศ</option>
                                        <option value="So2">ธรรมดา - นอกประเทศ</option>
                                    </select>
                                </div>

                                <div className="">
                                    <label className="label">
                                        <span className="label-text">ประเภทพัสดุ</span>
                                    </label>
                                    <select required name="parcelType" value={parcelCompanyList.parcelType || ''} className="input input-bordered w-full" onChange={handleChange}>
                                        <option value="" disabled>กรุณาเลือก</option>
                                        <option value="กล่อง">กล่อง</option>
                                        <option value="ซอง">ซอง</option>
                                    </select>
                                </div>

                                <div className="">
                                    <label className="label">
                                        <span className="label-text">ส่วน</span>
                                    </label>
                                    <input type="text" name="department" value={parcelCompanyList.department || ''} placeholder="กรอกข้อมูล" className="input input-bordered w-full" required onChange={handleChange} />
                                </div>

                                <div className="">
                                    <label className="label">
                                        <span className="label-text">Cost Center</span>
                                    </label>
                                    <input type="text" name="costCenter" value={parcelCompanyList.costCenter || ''} placeholder="กรอกข้อมูล" className="input input-bordered w-full" required onChange={handleChange} maxLength={10} />
                                </div>

                                <div className="">
                                    <label className="label">
                                        <span className="label-text">GL</span>
                                    </label>
                                    <input type="text" name="gl" value={parcelCompanyList.gl || ''} placeholder="กรอกข้อมูล" className="input input-bordered w-full" required onChange={handleChange} maxLength={9} minLength={9} />
                                </div>

                                <div className="">
                                    <label className="label">
                                        <span className="label-text">Order (ถ้ามี)</span>
                                    </label>
                                    <input type="text" name="order" value={parcelCompanyList.order || ''} placeholder="กรอกข้อมูล" className="input input-bordered w-full" onChange={handleChange} />
                                </div>

                                <div className="">
                                    <label className="label">
                                        <span className="label-text">เลขที่ (ถ้ามี)</span>
                                    </label>
                                    <input type="text" name="trackNum" value={parcelCompanyList.trackNum || ''} placeholder="กรอกข้อมูล" className="input input-bordered w-full" onChange={handleChange} onKeyDown={handleKeyDown} maxLength={13} />
                                </div>
                            </div>
                        </div>
                        <input type='submit' value={'แก้ไขข้อมูล'} className={`${styles.adminPageBtn} rounded-md hover:bg-hoverBackground w-full`} />
                    </form>
                )}
                {popupVisible && (
                    <div id='container' className='fixed z-20 inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center'>
                        <div className='bg-white py-5 sm:px-12 px-8 rounded-xl'>
                            <h3 className={`${styles.popupTextHead}`}>ตรวจสอบความถูกต้องของข้อมูล</h3>

                            <div className='mb-3 mr-3 flex-start'>
                                <h1 className={`${styles.popupTextHead}`}>ข้อมูลพัสดุ</h1>
                                <p className={`${styles.popupTextBody}`}>ชื่อ-นามสกุลผู้รับ : <label className="font-bold">{parcelCompanyList.receiverName}</label></p>
                                <p className={`${styles.popupTextBody}`}>รหัสไปรษณีย์ปลายทาง: <label className="font-bold">{parcelCompanyList.receiverZip}</label></p>
                                <p className={`${styles.popupTextBody}`}>ประเภทการจัดส่ง: <label className="font-bold">{shippedIDTranferIDToText(parcelCompanyList.shippedID)}</label></p>
                                <p className={`${styles.popupTextBody}`}>ประเภทพัสดุ: <label className="font-bold">{parcelCompanyList.parcelType}</label></p>
                                <p className={`${styles.popupTextBody}`}>ส่วน: <label className="font-bold">{parcelCompanyList.department}</label></p>
                                <p className={`${styles.popupTextBody}`}>Cost Center: <label className="font-bold">{parcelCompanyList.costCenter}</label></p>
                                <p className={`${styles.popupTextBody}`}>GL: <label className="font-bold">{parcelCompanyList.gl}</label></p>
                                <p className={`${styles.popupTextBody}`}>Order: <label className="font-bold">{parcelCompanyList.order}</label></p>
                                <p className={`${styles.popupTextBody}`}>Tracking Number: <label className="font-bold">{parcelCompanyList.trackNum}</label></p>
                            </div>

                            <div className='flex justify-end'>
                                <button className={`${styles.confirmBtn}`} onClick={() => { setPopupVisibility(false); handleEditParcel() }}>ยืนยัน</button>
                                <button className={`${styles.cancelBtn}`} onClick={() => setPopupVisibility(!popupVisible)}>ยกเลิก</button>
                            </div>

                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default EditParcel