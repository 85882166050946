export function getToken() {

    const token = {
        accessToken: '',
        idToken: ''
    };

    const key = Object.keys(localStorage)

    for (let i = 0; i < localStorage.length; i++) {
        if (JSON.parse(localStorage.getItem(key[i])).credentialType === 'AccessToken') {
            token.accessToken = JSON.parse(localStorage.getItem(key[i])).secret.toString();
        }
        if (JSON.parse(localStorage.getItem(key[i])).credentialType === 'IdToken') {
            token.idToken = JSON.parse(localStorage.getItem(key[i])).secret.toString();
        }
    }

    console.log(token)
    return token
};